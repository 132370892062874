// material-ui
import { Box, Typography, useMediaQuery, useTheme, Stack } from '@mui/material';

import Profile from './Profile';
import MobileSection from './MobileSection';
import Notification from './Notification';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { allPaths } from '../../../../../utils/constants';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = (props) => {
  const { remainingDays } = props;
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  const { admin } = useSelector((state) => state.adminAuthReducer);
  return (
    <>
      {/* {!matchesXs && <Search />} */}
      {/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}

      {/* <Box sx={{ width: '100%', ml: 1 }} /> */}

      {/* <Notification /> */}

      <Box sx={{ width: '100%', ml: 1, textAlign: 'center', color: theme.palette.primary.dark }}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
          {admin?.isFreeTrial && (
            <Box sx={{ width: '100%' }}>
              {remainingDays > 0 ? (
                <>
                  You have{' '}
                  <Box sx={{ fontWeight: 'bold', display: 'inline-block' }}>
                    {remainingDays} {remainingDays === 1 ? 'day' : `days`}
                  </Box>{' '}
                  left in your Free Launch Trial.{' '}
                  <Typography
                    component={Link}
                    to={allPaths.PRICING}
                    sx={{ color: `${theme.palette.primary.dark} !important`, fontWeight: 600, textDecoration: 'underLine' }}
                  >
                    Upgrade Now
                  </Typography>
                </>
              ) : (
                <>
                  Your Free Launch Trial has been expired.{' '}
                  <Typography
                    component={Link}
                    to={allPaths.PRICING}
                    sx={{ color: `${theme.palette.primary.dark} !important`, fontWeight: 600, textDecoration: 'underLine' }}
                  >
                    Upgrade Now
                  </Typography>
                </>
              )}
            </Box>
          )}
          <Notification />
        </Stack>
      </Box>

      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
