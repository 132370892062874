import { createTheme } from '@mui/material/styles';

export const themeOptions = {
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
    marginBottom: '0px',
    pageTitle: {
      fontWeight: 700,
      lineHeight: 1.2,
      color: '#FFFFFF',
      fontSize: '70px'
    },
    pageSubTitle: {
      fontWeight: 500,
      lineHeight: 1.2,
      color: '#FFFFFF',
      display: 'block',
      fontSize: '50px'
    }
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          color: '#FF9315'
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#FF9315'
          }
        }
      }
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          border: '1px solid #E0E0E0',
          minWidth: '80%',
          minHeight: '40px',
          padding: '0 15px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          width: '100%',
          marginTop: '5px',
          marginBottom: '5px',
          fontSize: '15px',
          lineHeight: 1.5,
          fontWeight: 600,
          color: '#FF9315',
          display: 'block'
        }
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'desktop'
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: '#FF9315',
          color: '#FFFFFF'
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1536,
      xxl: 1920,
      desktop: 1368
    }
  },
  palette: {
    primary: {
      dark: '#FF9315',
      main: '#FFB63B',
      light: '#FFCC80',
      dark05: '#FF93150D',
      dark12: '#FF9315'
    },
    secondary: {
      dark: '#000000',
      main: '#2C2B2B',
      light: '#231F20',
      light40: '#231f2066',
      dark50: '#00000080',
      main90: '#2C2B2B'
    },
    custom: {
      white: '#FFFFFF',
      white50: '#ffffff80',
      white15: '#ffffff26',
      gray: '#E0E0E0',
      darkGray: '#999999',
      smoke: '#6C6C6C',
      smokeLight: '#939393',
      lightGray: '#A5A5A5',
      silver: '#D2D2D2',
      lightPink: '#FFF9F3'
    }
  }
};

let theme = createTheme(themeOptions);
theme.typography.pageTitle = {
  ...theme.typography.pageTitle,
  [theme.breakpoints.down('sm')]: {
    fontSize: '50px'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '60px'
  }
};

theme.typography.pageSubTitle = {
  ...theme.typography.pageSubTitle,
  [theme.breakpoints.down('sm')]: {
    fontSize: '30px'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '40px'
  }
};

export default theme;
