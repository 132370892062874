import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { storLocModalId, albaRacingModelId } from '../../utils/constants';
import parse from 'html-react-parser';

// Custom Hooks
import useIframe from '../../Hooks/useIframe';

const ProductDescription = (props) => {
  const { modelData } = props;
  const IsIframe = useIframe();

  if (modelData.modelId === storLocModalId) {
    return (
      <Grid>
        <Typography gutterBottom variant="h4">
          Stor-Loc Cabinet
        </Typography>
        <Typography paragraph={true}>Fully Mig Welded Corners</Typography>
        <Typography paragraph={true}>Made in the USA with 55 year satisfaction guarantee</Typography>
        <Typography paragraph={true}>Optional 11 gauge full length fork channels</Typography>
        <Typography gutterBottom variant="h4">
          Drawers
        </Typography>
        <Typography paragraph={true}>400 pound capacity per drawer</Typography>
        <Typography paragraph={true}>11 gauge steel modular Guide Rails</Typography>
        <Typography paragraph={true}>11 gauge steel Carriages with six steel ball and four steel roller bearings per drawer</Typography>
        <Typography paragraph={true}>11 gauge steel Drawer Tracks</Typography>
      </Grid>
    );
  } else if (IsIframe && modelData.modelId === albaRacingModelId) {
    return <Grid></Grid>;
  } else {
    return (
      <Grid>
      <Typography gutterBottom variant="h4"
      sx={{ display: modelData.modelId === '661c2a0eb1f03a00158f70dc' ? 'none' : 'block' }}>
        About This Item
      </Typography>
      <Typography paragraph={true}> {parse(modelData?.description || '')}</Typography>
    </Grid>
    );
  }
};

ProductDescription.propTypes = {
  modelData: PropTypes.object.isRequired
};

export default ProductDescription;
