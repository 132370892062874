import { Box, Container, Grid, styled, Typography, useTheme } from '@mui/material';
import { SectionTitle, SectionWrapper, SectionSubTitle } from '../Common';

// eslint-disable-next-line  no-unused-vars
const LogoWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: '20px',
  paddingTop: '20px'
}));

// eslint-disable-next-line  no-unused-vars
const OurPartners = (props) => {
  const theme = useTheme();

  return (
    <SectionWrapper>
      <Container>
        <SectionTitle sx={{ textAlign: 'center' }}>
          <Typography variant="span">Our </Typography>
          <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
            Partners
          </Typography>
          <SectionSubTitle sx={{ textAlign: 'center', margin: '40px auto', maxWidth: '100% !important' }}>
            We work closely with our partners to ensure that every product and service we offer meets our high standards and exceeds your
            expectations.
          </SectionSubTitle>
        </SectionTitle>

        <Grid
          container
          sx={{
            '.border-bottom': {
              borderBottom: {
                xs: 'none',
                md: `1px solid ${theme.palette.custom.gray}`
              }
            },
            '.middle-logo': {
              borderLeft: {
                xs: 'none',
                md: `1px solid ${theme.palette.custom.gray}`
              },
              borderRight: {
                xs: 'none',
                md: `1px solid ${theme.palette.custom.gray}`
              }
            },
            '.partner-logo': {
              maxWidth: '90%'
            }
          }}
        >
          <Grid className="border-bottom" item xs={6} sm={6} md={4}>
            <LogoWrapper
              className="partner-logo"
              component="img"
              src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/50a77237-b904-4b8c-268d-4ac76e756e00/public"
            />
          </Grid>
          <Grid className="border-bottom middle-logo" item xs={6} sm={6} md={4}>
            <LogoWrapper
              className="partner-logo"
              component="img"
              height={"145px"}
              width={'319px'}
              // src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/7a24563b-3e8e-482b-765b-d1449c6ead00/public"
              src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/9d5d9d8a-6e26-4aaa-732a-5a1b7d3bda00/public"
            />
          </Grid>
          <Grid className="border-bottom" item xs={6} sm={6} md={4}>
            <LogoWrapper
              className="partner-logo"
              component="img"
              src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/aa690854-5cd8-422e-b6d0-dec11e854a00/public"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <LogoWrapper
              className="partner-logo"
              component="img"
              height={"145px"}
              width={'319px'}
              // src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/002504a1-0d54-4a30-7906-e970cf556c00/public"
              src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/5caca3ce-8047-450e-72de-c3cc43b46c00/public"
            />
          </Grid>
          <Grid className="middle-logo" item xs={6} sm={6} md={4}>
            <LogoWrapper
              className="partner-logo"
              component="img"
              height={"145px"}
              width={'319px'}
              // src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/3fa23d32-8f95-4234-fc9b-363ce330c500/public"
              src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/42b2aa73-b3e1-47b1-ef9a-e2ad1dd11000/public"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <LogoWrapper
              className="partner-logo"
              component="img"
              height={"145px"}
              width={'319px'}
              // src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/50b13149-0185-4ade-d6aa-c30fb440c300/public"
              src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/12eca9f5-a509-4986-cef1-45db2ad0b700/public"
            />
          </Grid>
        </Grid>
      </Container>
    </SectionWrapper>
  );
};

export default OurPartners;
