import { useState } from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';
import ProductBox from './ProductBox';
import Loader from '../MenuLayout/Loader';
import {
  SectionTitle,
  SectionTitleWrapper,
  SectionWrapper,
  HomeProductSliderWrapper,
  SectionSubTitle,
  NoProducts,
  ProductExploreAll
} from '../Common';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useCallback } from 'react';
import { POST } from '../../utils/apis';
import axios from 'axios';
import { useEffect } from 'react';
import { allPaths } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';

const FeaturedProducts = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = useCallback(() => {
    let postData = {};
    postData.filterBy = {
      isFeatured: true
    };
    postData.perPage = 4;
    setLoading(true);
    axios
      .post(POST.GET_ALL_MODELS, postData)
      .then((res) => {
        const { data } = res;
        setProducts(data?.data || []);
      })
      .catch((e) => {
        console.log('e', e);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return (
    <SectionWrapper>
      <Container>
        <SectionTitleWrapper>
          <SectionTitle>
            {/* <Typography variant="span">Featured </Typography>
            <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
              Products{' '}
            </Typography> */}
            <Typography variant='span'>Demo</Typography>
            <Typography
             variant='span'
             sx={{
              fontSize:{
                xs:'26px',
                md:'48px'
              },
              marginLeft:2,
              lineHeight:1.4,
              fontWeight:700,
              color: theme.palette.primary.dark, 
            }}
             >Spokbee </Typography>
            <Typography variant='span'>3D Configurator</Typography>
            <SectionSubTitle>
              {`Check out Spokbee’s product configuration Demos to see our capabilities`}
            </SectionSubTitle>
          </SectionTitle>
          <ProductExploreAll onClick={() => navigate(`${allPaths.PRODUCTS}/featured`)}>
            <Typography variant="span">Explore All</Typography>
            <KeyboardArrowRightIcon sx={{ ml: '10px' }} />
          </ProductExploreAll>
        </SectionTitleWrapper>

        <HomeProductSliderWrapper>
          {loading && <Loader />}
          {loading === false && (
            <>
              {products && products.length === 0 && <NoProducts />}
              {products && products.length > 0 && (
                <Grid container spacing="34px">
                  {products.map((prod, i) => {
                    return <ProductBox key={`featured-${i}`} product={prod} />;
                  })}
                </Grid>
              )}
            </>
          )}
        </HomeProductSliderWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default FeaturedProducts;
