import { FormLabel, Grid, Input, Typography, Box,useTheme } from '@mui/material';
import React from 'react';
import { ErrorMessage, FormControlBox, RequiredAsterisk } from '../../../Components/Common';
import { Controller } from 'react-hook-form';

const TutorialandResources = (props) => {
  const theme = useTheme();
  const { control, errors, setValue } = props;
  return (
    <Grid container margin={2}>
      <Box>
        <Typography variant="h6" color={theme.palette.primary.main}>Tutorial and Resources for Setup Process</Typography>
        <Typography>
          Welcome to the Spokbee Setup Tutorial and Resources page! Here, we provide you with all the tools and information you need to get
          your storefront up and running smoothly. Our goal is to make your onboarding experience as seamless as possible.
        </Typography>
      </Box>

      <Box marginTop={4}>
        <Typography variant="h6" color={theme.palette.primary.main}>Video Tutorials</Typography>
        <Typography>
          YouTube Channel: Dive into our video tutorials on the <a href ='https://www.youtube.com/@spokbee' target='_blank'>Spokbee YouTube Channel</a>. Our playlist covers everything from initial setup
          to advanced product configuration tips..
        </Typography>
      </Box>

      <Box marginTop={4}>
        <Typography variant="h6" color={theme.palette.primary.main}>Step-by-Step Guides (coming soon)</Typography>
        <Typography>
          Getting Started: New to Spokbee? Our step-by-step guides will walk you through the setup process, from creating your seller
          profile to listing your first product.
        </Typography>
      </Box>

      <Box marginTop={4}>
        <Typography variant='h6' color={theme.palette.primary.main}>
        Advanced Configuration Resources (coming soon):
        </Typography>
        <Typography>
        Ready to take your store to the next level? Explore our advanced guides on customization options, setting up complex product configurations, and utilizing our powerful pricing algorithms.
        </Typography>
      </Box>

      <Box marginTop={4}>
        <Typography variant='h6' color={theme.palette.primary.main}>
        FAQ Section (coming soon)
        </Typography>
      </Box>

      <Box marginTop={4}>
        <Typography variant="h6" color={theme.palette.primary.main}>Live Support</Typography>
        <Typography>Need More Help?: If you can't find what you're looking for or need personalized assistance, our support team is here to help. Reach out via our Support Page for direct assistance</Typography>
      </Box>
    </Grid>
  );
};

export default TutorialandResources;
