import { Box } from '@mui/material';
import './Loader.css';

// eslint-disable-next-line  no-unused-vars
const Loader = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '150px',
          height: '150px',
          transform: 'scale(.25,.25)',
          '& > .MuiBox-root': {
            width: '53px',
            height: '53px',
            position: 'absolute',
            top: '50%',
            transform: 'translate(0,-50%)',
            opacity: '0',
            borderRadius: '50%',
            backgroundColor: '#FF9315'
          }
        }}
      >
        <Box sx={{ animation: 'animation-dots 1.5s infinite linear 1s' }}></Box>
        <Box sx={{ animation: 'animation-dots 1.5s infinite linear 0.5s' }}></Box>
        <Box sx={{ animation: 'animation-dots 1.5s infinite linear' }}></Box>
      </Box>
    </Box>
  );
};

export default Loader;
