import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  // Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// project import
import MainCard from '../../../../../Admin/Components/MainCard';
import Transitions from '../../../../../Admin/Components/@extended/Transitions';
import ProfileTab from './ProfileTab';

// assets
// import avatar1 from '../../../../../assets/images/users/avatar-1.png';
import { LogoutOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { removeCustomer } from '../../../../../Redux/actions/customerAuthActions';
import { useNavigate } from 'react-router-dom';
import { allPaths } from '../../../../../utils/constants';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { customer } = useSelector((state) => state.customerAuthReducer || { customer: null });

  const handleLogout = async () => {
    let redirectTo = '';
    if (customer?.userRole == 3) {
      redirectTo = allPaths.CUSTOMER.LOGIN;
    }
    dispatch(removeCustomer());
    navigate(redirectTo);
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
          <Typography variant="subtitle1">{`${customer?.fname} ${customer?.lname}`}</Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar
                              alt="profile user"
                              src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/2a72ad48-47e8-40d9-dabe-a8a7c1b4d000/public"
                              sx={{ width: 32, height: 32 }}
                            />
                            <Stack>
                              <Typography variant="h6">{`${customer?.fname} ${customer?.lname}`}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        {(customer && customer.userRole == 3) &&
                            (<Grid item>
                              <IconButton size="large" color="secondary" onClick={handleLogout}>
                                <LogoutOutlined />
                              </IconButton>
                            </Grid>
                          )}
                      </Grid>
                    </CardContent>
                    {/* {open && <ProfileTab handleLogout={handleLogout} />} */}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
