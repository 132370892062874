// assets
import { MdDashboard, MdAttachMoney, MdShoppingCart  } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { LuFileBox } from "react-icons/lu";
import { allPaths } from "../../../utils/constants";

// // icons
// const icons = {
//   DashboardOutlined,
//   DollarOutlined
// };

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const customer = {
  id: 'main-menu',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: allPaths.CUSTOMER.DASHBOARD,
      icon: MdDashboard,
      breadcrumbs: false
    },
    {
      id: 'orders',
      title: 'Orders',
      type: 'item',
      url: allPaths.CUSTOMER.ORDERS,
      icon: MdShoppingCart,
      breadcrumbs: true,
    },
  ]
};

export default customer;