import { MODELS, MATERIALS } from '../types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case MODELS: {
      return { ...state, allModels: action.allModels };
    }
    case MATERIALS: {
      return { ...state, allMaterials: action.allMaterials };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
