import React, { useState } from 'react';
import { Dialog, Box, CircularProgress, DialogTitle, DialogActions, Button, DialogContent, TextField } from '@mui/material';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

import { useSnackbar } from 'notistack';
import { errorMessage } from '../../utils/helpers';
import { POST } from '../../utils/apis';
import { CONFIGURATIONINPUTTYPES } from '../../utils/constants';

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  notes: yup.string()
});
const generateConfigLabelString = (configParams, configurationInputs) => {
  let configObject = {};
  Object.keys(configParams).forEach((index) => {
    const param = configParams[index];

    if (param.type === CONFIGURATIONINPUTTYPES.dropDown) {
      const options = param?.message?.options;
      const selectedOptionValue = configurationInputs[param.message.parameterId];
      const selectedOption = options.find((opt) => {
        return opt.message.option == selectedOptionValue;
      });

      configObject[param.message.parameterId] = selectedOption.message.optionName;
    } else {
      configObject[param.message.parameterId] = configurationInputs[param.message.parameterId];
    }
  });
  return configObject;
};

function DownloadQuote(props) {
  const {
    GeneratePDF,
    productPrice,
    isPreview,
    modelRefreshing,
    setModelRefreshing,
    StorLocNewSchema,
    dataImg,
    modelId,
    currentConfiguration,
    configurationInputs,
    configurator
  } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleSubmitAndDownloadQuote = async (formData) => {
    setIsSubmitting(true);
    setModelRefreshing(true);

    const data = {
      ...formData,
      modelId,
      configuration: currentConfiguration,
      isFrom: 'spokbee',
      isNewSchema: StorLocNewSchema,
      dataImg: dataImg,
      productPrice: productPrice,
      filledFor: 'submitQuote'
    };

    const configurationInputsLabel = generateConfigLabelString(configurator?.configurationParameters, configurationInputs);

    const PDFData = new FormData();
    PDFData.append('configuration', JSON.stringify(configurationInputs));
    PDFData.append('configurationLabels', JSON.stringify(configurationInputsLabel));
    PDFData.append('dataImg', dataImg);
    PDFData.append('productPrice', productPrice);
    PDFData.append('isNewSchema', StorLocNewSchema);
    PDFData.append('modelId', modelId);

    try {
      const submitResponse = await axios.post(POST.SAVE_USER_DATA, data);
      if (submitResponse.data.success === true) {
        const pdfResponse = await axios.post(POST.PDF_GENERATE, PDFData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          responseType: 'arraybuffer'
        });

        const blob = new Blob([pdfResponse.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${modelId}.pdf`;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
        closeDialog();
      }
    } catch (err) {
      console.error('Error during form submission', err);
      enqueueSnackbar(err?.message || errorMessage(), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      setModelRefreshing(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        className="Button mt-2"
        onClick={openDialog}
        disabled={isPreview || modelRefreshing || productPrice == ''}
      >
        Download Quote
      </Button>

      <Dialog onClose={closeDialog} open={isDialogOpen}>
        <DialogTitle
          sx={{
            color: 'black',
            fontWeight: 'bold',
            fontSize: '20px',
            padding: '16px'
          }}
        >
          Enter Your Details
        </DialogTitle>

        <DialogContent>
          {isSubmitting && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(192,192,192,0.5)',
                zIndex: 1
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <form>
            <TextField
              label="Name"
              fullWidth
              margin="normal"
              {...register('name')}
              error={!!errors.name}
              helperText={errors.name?.message}
              sx={{
                '& .MuiInputLabel-root': {
                  margin: 0,
                  padding: 0
                }
              }}
            />
            <TextField
              label="Email"
              fullWidth
              margin="normal"
              type="email"
              {...register('email')}
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{
                '& .MuiInputLabel-root': {
                  margin: 0,
                  padding: 0
                }
              }}
            />
            <TextField label="Notes" fullWidth margin="normal" multiline rows={3} {...register('notes')} />
          </form>
        </DialogContent>

        <DialogActions
          sx={{
            padding: '16px'
          }}
        >
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit(handleSubmitAndDownloadQuote)} variant="contained" color="primary" disabled={isSubmitting}>
            Submit & Download Quote
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DownloadQuote;
