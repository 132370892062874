import { Fragment, memo, useEffect, useRef, useState } from 'react';

// used a package to control the configuration
import { Controller, useForm } from 'react-hook-form';

// Material UI imports

import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { enqueueSnackbar } from 'notistack';

const Input = styled(MuiInput)`
  width: 42px;
  padding: 0;
  border: none;
`;
const Configurator = memo((props) => {
  const { setModelRefreshing, configurator, setConfigurationInputs, cachedConfiguration } = props;

  const { control, watch, getValues, setValue } = useForm();

  useEffect(() => {
    const subscription = watch(() => {
      setModelRefreshing(true);
      clearTimeout(timerId?.current);
      timerId.current = null;
      timerId.current = window.setTimeout(myFunc, 1600);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleNumberChange = (value, field) => {
    // setValue(field?.name, value);
    value = eval(value).toFixed(1);
    const element = document.getElementById(`number-${field?.name}`);
    const holeDiameter = getValues('HoleDiameter');
    const outerDiameter = getValues('OuterDiameter');
    const innerDiameter = getValues('InnerDiameter');

    if (field.name === 'OuterDiameter') {
      if (value > holeDiameter) {
        setValue(field?.name, value);
      } else {
        element.value = outerDiameter;
        enqueueSnackbar('can not set the value to less than value of Hole Diameter!', { variant: 'error' });
      }
    } else if (field.name === 'HoleDiameter') {
      if (value < innerDiameter) {
        element.value = holeDiameter;
        enqueueSnackbar('can not set the value to less than value of Inner Diameter!', { variant: 'error' });
      } else if (value > outerDiameter) {
        element.value = holeDiameter;
        enqueueSnackbar('can not set the value to greater than value of Hole Diameter!', { variant: 'error' });
      } else {
        setValue(field?.name, value);
      }
    } else if (field.name === 'InnerDiameter') {
      if (value < holeDiameter) {
        setValue(field?.name, value);
      } else {
        element.value = innerDiameter;
        enqueueSnackbar('can not set the value to greater than value of Hole Diameter!', { variant: 'error' });
      }
    } else {
      setValue(field?.name, value);
    }
  };

  const handleSliderChange = (value, field) => {
    const element = document.getElementById(`number-${field?.name}`);

    const holeDiameter = getValues('HoleDiameter');
    const outerDiameter = getValues('OuterDiameter');
    const innerDiameter = getValues('InnerDiameter');
    value = eval(value).toFixed(1);
    if (field.name === 'OuterDiameter') {
      if (value > holeDiameter) {
        field.onChange(value);
        element.value = value;
      } else {
        enqueueSnackbar('can not set the value to less than value of Hole Diameter!', { variant: 'error' });
      }
    } else if (field.name === 'HoleDiameter') {
      if (value < innerDiameter) {
        enqueueSnackbar('can not set the value to less than value of Inner Diameter!', { variant: 'error' });
      } else if (value > outerDiameter) {
        enqueueSnackbar('can not set the value to greater than value of Hole Diameter!', { variant: 'error' });
      } else {
        field.onChange(value);
        element.value = value;
      }
    } else if (field.name === 'InnerDiameter') {
      if (value < holeDiameter) {
        field.onChange(value);
        element.value = value;
      } else {
        enqueueSnackbar('can not set the value to greater than value of Hole Diameter!', { variant: 'error' });
      }
    } else {
      field.onChange(value);
    }
  };

  const timerId = useRef(null);

  const myFunc = () => {
    clearTimeout(timerId?.current);
    timerId.current = null;
    setConfigurationInputs(getValues());
  };

  return (
    <>
      {configurator?.configurationParameters &&
        configurator?.configurationParameters.map((v, i) => {
          let cachedConfigValue = {};
          if (cachedConfiguration?.configuration && cachedConfiguration?.configuration?.length > 0) {
            cachedConfigValue = cachedConfiguration?.configuration.find((param) => {
              return param.identifier === v.message.parameterId;
            });
          }
          let defaultVal = '';
          const label = (
            // <Grid item xs={3} key={i}>
            <label className="configParamLabel">
              {v?.message?.parameterName}{' '}
              {v?.message?.rangeAndDefault?.message?.units ? `(${v?.message?.rangeAndDefault?.message?.units})` : null}
            </label>
            // </Grid>
          );
          // maxDefault value shoul be 0 because Cola bottle has 0 as max value so it will affect the configurator value and crash the onshape API
          const min = v.message.rangeAndDefault?.message?.minValue * 1 || 0; // eslint-disable-line no-case-declarations
          const max = v.message.rangeAndDefault?.message?.maxValue * 1 || 0; // eslint-disable-line no-case-declarations
          const isDegrees = v.message.rangeAndDefault?.message?.units == 'degree'; // eslint-disable-line no-case-declarations

          // let stepValue = eval(((max - min) / 3).toFixed(1)); // eslint-disable-line no-case-declarations
          // if (stepValue > 1) {
          //   stepValue = 1;
          // }
          let stepValue = 0.1;

          defaultVal = v.message.rangeAndDefault?.message?.defaultValue || min;

          if (cachedConfiguration?.configuration?.length > 0) {
            defaultVal = cachedConfigValue?.value.replace(/[^0-9.]/g, '');
          }
          // eslint-disable-next-line no-case-declarations
          const marks = [
            {
              value: min,
              label: min.toString() + (isDegrees ? '°' : '')
            },
            {
              value: max,
              label: max.toString() + (isDegrees ? '°' : '')
            }
          ];

          return (
            <Fragment key={i}>
              <Controller
                key={i}
                name={v.message.parameterId}
                control={control}
                defaultValue={defaultVal}
                render={({ field }) => (
                  <Grid container={true} item xs={12} direction="row" justifyContent="space-between" sx={{ marginBottom: '15px' }}>
                    <Grid item xs={12} className="lable-name">
                      {label}
                    </Grid>
                    <Grid container item xs={12} alignItems="center" columnSpacing={2} justifyContent="space-between">
                      <Grid item xs={9} alignItems="center">
                        <Slider
                          size="small"
                          {...field}
                          onChange={(_, value) => {
                            // field.onChange(value);
                            handleSliderChange(value, field);
                          }}
                          max={max}
                          min={min}
                          step={stepValue}
                          marks={marks}
                        />
                      </Grid>
                      <Grid container item xs={3} alignItems="center">
                        <Input
                          id={`number-${v.message.parameterId}`}
                          size="small"
                          defaultValue={defaultVal}
                          disableUnderline={false}
                          onChange={(e) => handleNumberChange(e?.target?.value, field)}
                          inputProps={{
                            step: 0.1,
                            min: min,
                            max: max,
                            type: 'number'
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              />
            </Fragment>
          );
        })}
    </>
  );
});

export default Configurator;
