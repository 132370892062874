import { LOGIN_CUSTOMER, REMOVE_CUSTOMER } from '../types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_CUSTOMER: {
      return { ...state, customer: action.customer };
    }
    case REMOVE_CUSTOMER: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default reducer;
