import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const SnipcartContext = React.createContext();

export const SnipcartProvider = ({ children }) => {
  const [snipcartReady, setSnipcartReady] = useState(false);
  const { user: loggedinUser } = useSelector((state) => state.authReducer);

  React.useEffect(() => {
    const listenSnipcart = () => {
      document.addEventListener('snipcart.ready', () => {
        setSnipcartReady(true);
      });
    };

    if (typeof window !== 'undefined') {
      // don't run for SSR builds
      if (window.Snipcart !== undefined) {
        setSnipcartReady(true);
      } else {
        listenSnipcart();
      }
    }
  }, []);

  useEffect(() => {
    if (snipcartReady) {
      Snipcart.events.on('cart.confirmed', async (cartConfirmResponse) => {
        if (loggedinUser) {
          const requestOptions = {
            method: 'PUT',
            headers: {
              Authorization: `Basic ${btoa(process.env.REACT_APP_SNIPCART_SECRET_KEY)}`,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: cartConfirmResponse?.token, metadata: { spokbee_user_id: loggedinUser?._id } })
          };
          const req = await fetch(`https://app.snipcart.com/api/orders/${cartConfirmResponse?.token}`, requestOptions)
            .then(async (response) => {
              const isJson = response.headers.get('content-type')?.includes('application/json');
              const data = isJson && (await response.json());

              // check for error response
              if (!response.ok) {
                // get error message from body or default to response status
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }
              return data;
            })
            .then((responseData) => {
              console.log('Response Data:', responseData);
              // Handle the response data here
            })
            .catch((error) => {
              console.error('There was an error!', error);
            });
        }
      });
    }
  }, [snipcartReady]);

  return <SnipcartContext.Provider value={{ snipcartReady }}>{children}</SnipcartContext.Provider>;
};
