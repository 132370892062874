import { LOGIN_SHIPPOSELLER, REMOVE_SHIPPOSELLER } from '../types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_SHIPPOSELLER: {
      return { ...state, shippoSeller: action.shippoSeller };
    }
    case REMOVE_SHIPPOSELLER: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default reducer;