import { Box } from '@mui/material';
import { columnsWidthOptions, depthOptions, risersOptions } from './StorLocConfig';
import { useEffect } from 'react';

// thickness of the bottom of the cabinets are 127mm which is 5in
// const extraHeight = 5

const BoundingBox = (props) => {
  const { dimensions, jsonData, StorLocNewSchema , ProductDimentionRef ,  setProductDimensions} = props; // eslint-disable-line  no-unused-vars
  const columns = jsonData?.Columns;

  // if checkbox of wheels is checked that fixed value 7.5 inches is used in calculation
  const wheelHeight = jsonData.Wheels === true ? 7.5 : 0;
  // top height is fixed to 1.75 inches

  // Convert Milimeter values in inches
  const convertMMToInch = (mm) => {
    const mToInchRate = 25.4;
    return mm / mToInchRate;
  };

  // Round the number in the multiple of nearest number and default round number is 6
  const RoundedUpto = (value, roundedTo = 6) => {
    const modulas = value % roundedTo; // Find a modulas of number divided by round number
    const differnece = roundedTo - modulas; // Substract the modulas from round number
    return Math.round(value + differnece);
  };
  const RoundedToNearestQuarter = (value) => {
    return Math.round(value * 4) / 4;
  };

  let topHeight;
  let selectedDepth;
  let housingWidth;
  let topWidth;
  let topDepth;
  let housingHeight;
  let counterHeight;
  let selectedRiserOpt;
  let totalHeight;


  if (!StorLocNewSchema) {

    topHeight = jsonData?.Top?.Top === 'None' || jsonData?.Top?.Top === 'Stainless Steel Capped' ? 0 : 1.75;
    selectedDepth = depthOptions.find((depth) => depth.value === jsonData.Depth.depth)
    housingWidth = columns.reduce((prevVal, column) => {
      const columnOption = columnsWidthOptions.find((col) => col?.value === column?.width?.width);
      return prevVal + columnOption?.inchValue;
    }, 0);
    topWidth = jsonData?.Top?.Top === 'None' || jsonData?.Top?.Top === 'Stainless Steel Capped' ? 0 : RoundedUpto(housingWidth, 6).toFixed(0);
    topDepth =
      jsonData?.Top?.Top === 'None' || jsonData?.Top?.Top === 'Stainless Steel Capped'
        ? selectedDepth?.inchValue.toFixed(2)
        : RoundedUpto(selectedDepth?.inchValue, 6).toFixed(0);
    housingHeight = convertMMToInch(jsonData.Height);
    counterHeight = RoundedToNearestQuarter(housingHeight + 6 + wheelHeight + topHeight);
    selectedRiserOpt = risersOptions.find((riserOpt) => riserOpt?.value === jsonData?.Risers?.RiserHeight);
    totalHeight = RoundedToNearestQuarter(parseFloat(selectedRiserOpt?.inchValue + counterHeight + topHeight));

  } else {

    topHeight = jsonData?.Top === 'None' || jsonData?.Top === 'Stainless Steel Capped' ? 0 : 1.75;
    selectedDepth = depthOptions.find((depth) => depth.id === jsonData.Depth)
    housingWidth = columns.reduce((prevVal, column) => {
      const columnOption = columnsWidthOptions.find((col) => col.id === column);
      return prevVal + columnOption.inchValue;
    }, 0);
    topWidth = jsonData?.Top === 'None' || jsonData?.Top === 'Capped' ? 0 : RoundedUpto(housingWidth, 6).toFixed(0);
    topDepth =
      jsonData?.Top === 'None' || jsonData?.Top === 'Stainless'
        ? selectedDepth?.inchValue.toFixed(2)
        : RoundedUpto(selectedDepth?.inchValue, 6).toFixed(0);
    housingHeight = convertMMToInch(jsonData.Height);
    counterHeight = RoundedToNearestQuarter(housingHeight + 6 + wheelHeight + topHeight);
    selectedRiserOpt = risersOptions.find((riserOpt) => riserOpt?.inchValue === jsonData?.RiserHeight);
    totalHeight = RoundedToNearestQuarter(parseFloat(selectedRiserOpt?.inchValue + counterHeight + topHeight));

  }

  // console.log("BoundingBox ~ topHeight:", topHeight
  //   , selectedDepth
  //   , housingWidth
  //   , topWidth
  //   , topDepth
  //   , housingHeight
  //   , counterHeight
  //   , selectedRiserOpt
  //   , totalHeight)

  // find the option object which is selected in configurator
  // const selectedDepth = depthOptions.find((depth) => depth.value === jsonData.Depth.depth);
  // const housingWidth = columns.reduce((prevVal, column) => {
  //   const columnOption = columnsWidthOptions.find((col) => col.value === column?.width?.width);
  //   return prevVal + columnOption.inchValue;
  // }, 0);

  // const topWidth =
  //   jsonData?.Top?.Top === 'None' || jsonData?.Top?.Top === 'Stainless Steel Capped' ? 0 : RoundedUpto(housingWidth, 6).toFixed(0);
  // const topDepth =
  //   jsonData?.Top?.Top === 'None' || jsonData?.Top?.Top === 'Stainless Steel Capped'
  //     ? selectedDepth?.inchValue.toFixed(2)
  //     : RoundedUpto(selectedDepth?.inchValue, 6).toFixed(0);
  //Height is in mm, so it must be converted to inch
  // const housingHeight = convertMMToInch(jsonData.Height);
  // const counterHeight = RoundedToNearestQuarter(housingHeight + 6 + wheelHeight + topHeight);
  // const selectedRiserOpt = risersOptions.find((riserOpt) => riserOpt.value === jsonData.Risers.RiserHeight);
  // const totalHeight = RoundedToNearestQuarter(parseFloat(selectedRiserOpt.inchValue + counterHeight + topHeight));

  useEffect(() => {
      const height = totalHeight + wheelHeight
      const width = selectedDepth.inchValue;
      let length = ''; 
  
      if(jsonData?.Top == 'Stainless' || jsonData?.Top == 'Maple') {
        length = topWidth ? topWidth : housingWidth
      } else {
        length = housingWidth
      }
      ProductDimentionRef.current = { isStorloc : true,  dimentions : { height, width, length , dimensionsUnit: "IN"} }
  }, [dimensions]);

  return (
    <Box
      marginTop={2}
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'none',
          md: '50% 1fr'
        },
        color: '#000'
      }}
    >
      <Box>
        <label className="boundingBoxLabel">Housing Width:</label> {housingWidth.toFixed(2)} in
      </Box>
      {
        StorLocNewSchema ? (
          jsonData?.Top !== 'Capped' && jsonData?.Top !== 'None' && (
            <Box>
              <label className="boundingBoxLabel">Top Width:</label> {topWidth} in
            </Box>
          )
        ) : (
          jsonData?.Top !== 'Stainless Steel Capped' && jsonData?.Top !== 'None' && (
            <Box>
              <label className="boundingBoxLabel">Top Width:</label> {topWidth} in
            </Box>
          )
        )
      }
      <Box>
        <label className="boundingBoxLabel">Housing Depth:</label> {selectedDepth.inchValue.toFixed(2)} in
      </Box>
      <Box>
        <label className="boundingBoxLabel">Top Depth:</label> {topDepth} in
      </Box>
      <Box>
        <label className="boundingBoxLabel">Counter Height:</label> {counterHeight.toFixed(2)} in
      </Box>
      <Box>
        <label className="boundingBoxLabel">Total Height:</label> {totalHeight.toFixed(2)} in
      </Box>
    </Box>
  );
};


export default BoundingBox;
