import { Box, Container, MenuList, Stack, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { FooterMenuItem } from '../Common';
import { allPaths } from '../../utils/constants';

// eslint-disable-next-line  no-unused-vars
const Copyright = (props) => {
  const theme = useTheme();
  return (
    <Container
      maxWidth="desktop"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginY: {
          xs: '10px',
          md: '20px'
        },
        flexDirection: {
          xs: 'column-reverse',
          md: 'row'
        },
        rowGap: '10px'
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: {
            xs: '14px',
            md: '16px'
          },

          lineHeight: 1.5,
          color: theme.palette.secondary.main
        }}
      >
        Copyright ©2024 Spokbee.com, All Rights Reserved
      </Typography>
      <Box>
        <MenuList
          component={Stack}
          sx={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: {
              xs: 'start',
              md: 'center'
            },
            gap: {
              xs: '0',
              md: '2px'
            },
            padding: '0',
            '.MuiMenuItem-root': {
              marginBottom: '0px'
            }
          }}
        >
          <FooterMenuItem>
            <Link to={allPaths.PRIVACY_POLICY}>Privacy policy</Link>
          </FooterMenuItem>
          <FooterMenuItem>|</FooterMenuItem>
          <FooterMenuItem>
            <Link to={allPaths.TERMS_CONDITIONS}>Terms & Conditions</Link>
          </FooterMenuItem>
        </MenuList>
      </Box>
    </Container>
  );
};

export default Copyright;
