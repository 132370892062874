import { Box, MenuList, Typography, Container, useTheme } from '@mui/material';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { convertTitle } from '../../utils/helpers';
import Divider from '@mui/material/Divider';
import Copyright from '../Footer/Copyright';
import { FooterMenuItem, MenuTitle } from '../Common';
import NewsLetterSubscriber from '../Footer/NewsLetterSubscriber';
import { allPaths } from '../../utils/constants';

const Footer = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { categories } = props;

  const sellMenuItems = [
    {
      title: 'Sell on Spokbee',
      url: allPaths.SELL_ON_SPOKBEE
    },
    {
      title: 'Seller Login',
      url: allPaths.SELLER.LOGIN
    }
    // {
    //   title: 'Login',
    //   url: allPaths.LOGIN
    // }
  ];

  const otherMenuItems = [
    {
      title: 'About Us',
      url: allPaths.ABOUT
    },
    {
      title: 'FAQs',
      url: allPaths.FAQS
    },
    {
      title: 'Careers',
      url: '/'
    },
    {
      title: 'Contact Us',
      url: allPaths.CONTACT
    },
    {
      title: 'Terms & Conditions',
      url: allPaths.TERMS_CONDITIONS
    }
  ];

  return (
    <>
      {/* Upcoming Launches News Letter */}
      {/* <NewsLetterSubscriber /> */}
      {/* Footer */}
      <Box
        className="menu-footer"
        sx={{
          background: 'url(https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/4766ecb4-52e2-4d26-2a60-98045ff04000/public)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Container
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: {
              xs: 'start',
              md: 'space-between'
            },
            rowGap: '20px',
            marginBottom: {
              xs: '30px',
              md: '38px'
            },
            paddingTop: {
              xs: '25px',
              md: '50px'
            },
            '> .MuiBox-root': {
              flexBasis: {
                xs: '45%',
                sm: '23%',
                md: '18%'
              }
            }
          }}
        >
          {/* Footer Part 1 Intro */}
          <Box
            sx={{
              flexBasis: {
                xs: '100% !important',
                md: '18% !important'
              },
              textAlign: {
                xs: 'left',
                sm: 'center',
                md: 'left'
              }
            }}
          >
            <Box
              component="img"
              src="/assets/spokbee.png"
              alt="Spokbee"
              sx={{
                marginBottom: '20px'
              }}
              onClick={() => navigate(allPaths.HOME)}
            />

            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: 1.5,
                color: theme.palette.custom.smoke,
                marginBottom: '20px'
              }}
            >
              {/* Lorem Ipsum is simply dummy text
                of the printing and typesetting. */}
            </Typography>
            {/* <Box 
              className="social-media-wrapper"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'center',
                  md: 'flex-start'
                },
                gap: '12px',
              }}
            > */}
            {/* <Box 
                component='img' 
                src='https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/226a46dd-a93b-4d74-8ede-8a32f0220a00/public'
                onClick={(e) => window.open(process.env.REACT_APP_FACEBOOK_LINK)} 
                sx={{
                  width: '34px',
                  height: '34px',
                  cursor: 'pointer'
                }}
              />
              <Box 
                component='img' 
                src='https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/a8d0c9e0-8285-493c-e64a-ccb66db30600/public'
                onClick={(e) => window.open(process.env.REACT_APP_INSTAGRAM_LINK)}
                sx={{
                  width: '34px',
                  height: '34px',
                  cursor: 'pointer'
                }}
              /> */}
            {/* <Box 
                component='img' 
                src='https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/32e89871-7581-477d-2b68-d04ea2f9f600/public'
                onClick={(e) => window.open(process.env.REACT_APP_TWITTER_LINK)}
                sx={{
                  width: '34px',
                  height: '34px',
                  cursor: 'pointer'
                }}
              />
            </Box> */}
          </Box>
          {/* Footer Part 2 Categories List */}
          <Box>
            <MenuList>
              <MenuTitle>Products</MenuTitle>
              {categories.map((category, i) => {
                return (
                  <FooterMenuItem key={`footer1-${i}`}>
                    <Link to={`${allPaths.PRODUCTS}/category/${category._id}`}>{convertTitle(category.cat_name)}</Link>
                  </FooterMenuItem>
                );
              })}
            </MenuList>
          </Box>
          {/* Footer Part 3 Sell Menu */}
          <Box>
            <MenuList>
              <MenuTitle>Sell</MenuTitle>
              {sellMenuItems.map((meniItem, i) => {
                return (
                  <FooterMenuItem key={`footer2-${i}`}>
                    <Link to={meniItem.url}>{meniItem.title}</Link>
                  </FooterMenuItem>
                );
              })}
            </MenuList>
          </Box>
          {/* Footer Part 4 Other Menu */}
          <Box>
            <MenuList>
              <MenuTitle>Company</MenuTitle>
              {otherMenuItems.map((meniItem, i) => {
                return (
                  <FooterMenuItem key={`footer3-${i}`}>
                    <Link to={meniItem.url}>{meniItem.title}</Link>
                  </FooterMenuItem>
                );
              })}
            </MenuList>
          </Box>
          {/* Footer Part 4 Other Menu */}
          <Box>
            <MenuList>
              <MenuTitle>Contact Us</MenuTitle>
              <FooterMenuItem>
                <Link to="/">
                  <Box
                    component="img"
                    src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/d966c78d-ccd9-488d-65ad-799bffa80c00/public"
                    sx={{
                      width: '34px',
                      height: '34px',
                      mr: '10px',
                      display: 'inline-block',
                      float: 'left'
                    }}
                  />
                  +1 (612) 834-3590
                </Link>
              </FooterMenuItem>
              <FooterMenuItem>
                <Link to="/">
                  <Box
                    component="img"
                    src="https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/3f1afa5f-915c-4389-7ba2-8fed48cc8b00/public"
                    sx={{
                      width: '34px',
                      height: '34px',
                      mr: '10px',
                      display: 'inline-block',
                      float: 'left'
                    }}
                  />
                  mac@spokbee.com
                </Link>
              </FooterMenuItem>
            </MenuList>
          </Box>
        </Container>
      </Box>
      <Divider
        sx={{
          borderColor: theme.palette.custom.gray
        }}
      />
      <Copyright />
    </>
  );
};

export default Footer;
