import { Avatar, Card, CardContent, DialogContent, Stack, Typography } from '@mui/material';
import { BootstrapDialog, BootstrapDialogTitle } from '../Common';

const MaterialModal = (props) => {
  const { open, handleClose, availableMaterials, setActive, active, setSelectedMaterial } = props;
  return (
    <BootstrapDialog onClose={handleClose} open={open} aria-labelledby="shareModelLabel" aria-describedby="shareModelLabel">
      <BootstrapDialogTitle onClose={handleClose}>Select Material</BootstrapDialogTitle>
      <DialogContent dividers>
        <div>
          {availableMaterials?.map((v, i) => {
            return (
              <Card
                key={i}
                sx={{
                  minHeight: 170,
                  marginBottom: '20px',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setActive(i);
                  setSelectedMaterial(v);
                  handleClose();
                }}
                className={active === i ? 'select-card' : ''}
                data-bs-dismiss="modal"
              >
                <CardContent>
                  <div className="flex-row" key={i}>
                    <Typography gutterBottom variant="h5" component="div" className="card-title">
                      {v?.title}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Avatar alt="Spokbee material swatch" src={`${v?.swatch}`} sx={{ width: 56, height: 56 }} />
                    </Stack>
                  </div>
                  <Typography sx={{ fontWeight: 'bold' }} variant="h6" className="material-price card-subtitle mb-2" component="div">
                    {v?.price}
                  </Typography>
                  <Typography paragraph={true} lineHeight={2} className="material-description">
                    {v?.description}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default MaterialModal;
