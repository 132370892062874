import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Button, Divider, FormLabel, styled, Typography, useTheme } from '@mui/material';
import { SectionTitle, ErrorMessage, FormControlBox, SectionWrapper } from '../../Components/Common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Input as MuiInput } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { requiredMessage } from '../../utils/helpers';
import { POST } from '../../utils/apis';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const InfoTitle = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: 1.4,
  fontWeight: 400
}));

const InfoValue = styled(Typography)(() => ({
  fontSize: '30px',
  lineHeight: 1.4,
  fontWeight: 700,
  marginTop: '15px'
}));

const info = [
  {
    infoTitle: 'Telephone',
    infoValue: '+1 (612) 834-3590'
  },
  {
    infoTitle: 'General Email',
    infoValue: 'mac@spokbee.com'
  }
];

const validatonSchema = Yup.object().shape({
  firstname: Yup.string()
    .nullable()
    .trim()
    .required(requiredMessage('First Name'))
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  lastname: Yup.string()
    .nullable()
    .trim()
    .required(requiredMessage('Last Name'))
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    email: Yup.string().nullable().trim().required(requiredMessage('Email')),
    subject: Yup.string().nullable().trim().required(requiredMessage('Subject')),
  message: Yup.string().nullable().trim().required(requiredMessage('Message'))
});

const Contact = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validatonSchema),
    defaultValues: {
      firstname: '',
      lastname: '',
      email:'',
      subject: '',
      message: ''
    }
  });

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(POST.CONTACT_US, data)
      .then((res) => {
        if (res.status === 200) {
          const { message } = res.data;
          enqueueSnackbar(message, { variant: 'success' });
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
          reset({
            email: '',
            password: ''
          });
        }
      })
      .catch((e) => {
        const res = e.response;
        if (res?.data?.message) {
          enqueueSnackbar(res?.data?.message, { variant: 'error' });
        }
      });
  };

  return (
    <SectionWrapper>
      <Container>
        <Grid container textAlign="left" rowSpacing={6}>
          <Grid container item xs={12} md={6} spacing={6}>
            {info?.map((item, i) => {
              return (
                <Grid item xs={12} key={`contact-info-${i}`}>
                  <InfoTitle>{item.infoTitle}</InfoTitle>
                  <InfoValue>{item.infoValue}</InfoValue>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography>We&apos;re here to help!</Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '22px',
                lineHeight: 1.4,
                marginTop: '15px'
              }}
            >
              If you have any questions, concerns, or suggestions, please don&apos;t hesitate to get in touch with our team.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{
                '.MuiInput-root': {
                  display: 'block'
                }
              }}
            >
              <Grid container spacing={2} rowSpacing={0}>
                <Grid item xs={12} md={6}>
                  <FormControlBox>
                    <FormLabel>First Name</FormLabel>
                    <Controller
                      name="firstname"
                      control={control}
                      render={({ field }) => (
                        <MuiInput
                          {...field}
                          onChange={(value) => field.onChange(value)}
                          placeholder="First Name"
                          error={errors?.firstname ? true : false}
                        />
                      )}
                    />
                    {errors.firstname && <ErrorMessage msg={errors?.firstname?.message} />}
                  </FormControlBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlBox>
                    <FormLabel>Last Name</FormLabel>
                    <Controller
                      name="lastname"
                      control={control}
                      render={({ field }) => (
                        <MuiInput
                          {...field}
                          onChange={(value) => field.onChange(value)}
                          placeholder="Last Name"
                          error={errors?.lastname ? true : false}
                        />
                      )}
                    />
                    {errors.lastname && <ErrorMessage msg={errors?.lastname?.message} />}
                  </FormControlBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlBox>
                    <FormLabel>Email</FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <MuiInput
                          {...field}
                          onChange={(value) => field.onChange(value)}
                          placeholder="Email"
                          error={errors?.email ? true : false}
                          sx={{
                            minWidth: '100% !important'
                          }}
                        />
                      )}
                    />
                    {errors.email && <ErrorMessage msg={errors?.email?.message} />}
                  </FormControlBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlBox>
                    <FormLabel>Subject</FormLabel>
                    <Controller
                      name="subject"
                      control={control}
                      render={({ field }) => (
                        <MuiInput
                          {...field}
                          onChange={(value) => field.onChange(value)}
                          placeholder="Subject"
                          error={errors?.subject ? true : false}
                          sx={{
                            minWidth: '100% !important'
                          }}
                        />
                      )}
                    />
                    {errors.subject && <ErrorMessage msg={errors?.subject?.message} />}
                  </FormControlBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlBox>
                    <FormLabel>Message</FormLabel>
                    <Controller
                      name="message"
                      control={control}
                      render={({ field }) => (
                        <MuiInput
                          {...field}
                          onChange={(value) => field.onChange(value)}
                          placeholder="Message"
                          multiline={true}
                          maxRows={5}
                          minRows={5}
                          error={errors?.message ? true : false}
                          sx={{
                            minWidth: '100% !important'
                          }}
                        />
                      )}
                    />
                    {errors.message && <ErrorMessage msg={errors?.message?.message} />}
                  </FormControlBox>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlBox>
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </FormControlBox>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: {
              xs: '35px',
              md: '80px',
              lg: '100px'
            }
          }}
        >
          <SectionTitle sx={{ color: theme.palette.primary.main, textAlign: 'center' }}>Thank you for choosing Spokbee Inc.</SectionTitle>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '26px',
              lineHeight: 1.4,
              textAlign: 'center',
              color: theme.palette.secondary.main
            }}
          >
            where everyone wins!
          </Typography>
        </Grid>
      </Container>
    </SectionWrapper>
  );
};

export default Contact;
