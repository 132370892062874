import { Card, CardContent, CardActionArea, CardMedia, Typography, useTheme, Box } from '@mui/material';
import { BestSellingModels, newSchemaBestSellingModels } from './StorLocConfig';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  sm: {
    breakpoint: { max: 1024, min: 601 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1
  },
  xs: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  }
};

const StorLocBestSellingModels = (props) => {
  const { modelRefreshing, setStorLocModel, selectedModel, StorLocNewSchema } = props;
  // const spacingConfiguration = {
  //     noOfGrid: {
  //         xs: (modelRefreshing === true) ? 'auto' : 6,
  //         sm: (modelRefreshing === true) ? 'auto' : 4,
  //         lg: (modelRefreshing === true) ? 'auto' : 4,
  //     },
  //     contentJustification: (modelRefreshing === true) ? 'flex-start' : 'center',
  //     gridSpacing: (modelRefreshing === true) ? 2 : 3
  // }
  const theme = useTheme();

  let Models = []
  if(StorLocNewSchema){
    Models = newSchemaBestSellingModels
  } else {
    Models = BestSellingModels
  }

  

  return (
    <Card sx={{ p: 2, border: `1px solid ${theme.palette.primary.dark}`, borderRadius: '10px' }}>
      <Typography variant="h5" fontWeight="bold">
        Our Best Selling Models
      </Typography>
      <hr></hr>
      <br></br>
      <Carousel
        responsive={responsive}
        autoPlay={false}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
      >
        {Models?.map((mdl) => {
          return (
            <Box
              sx={{
                px: 2,
                height: '100%'
              }}
              key={`model-${mdl.model}`}
            >
              <Card
                sx={{
                  border: selectedModel == mdl.model ? `2px solid ${theme.palette.primary.dark}` : `none`,
                  boxShadow:
                    selectedModel == mdl.model
                      ? '0px 2px 1px -1px rgba(255,147,21,0.2), 0px 0px 1px 0px rgba(255,147,21,0.14), 0px 1px 3px 0px rgba(255,147,21,0.12)'
                      : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                  height: 'calc(100% - 5px)'
                }}
              >
                <CardActionArea
                  onClick={() => setStorLocModel(mdl.model, mdl.configuration)}
                  sx={{
                    pointerEvents: modelRefreshing === true ? 'none' : 'initial',
                    opacity: modelRefreshing === true ? 0.15 : 1
                  }}
                >
                  <CardMedia
                    component="img"
                    image={mdl.imageUrl}
                    alt={`Stor-Loc ${mdl.model}`}
                    sx={{
                      objectFit: 'contain',
                      maxHeight: '145px'
                    }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {mdl.model}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {mdl.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          );
        })}
      </Carousel>
    </Card>
  );
};

export default StorLocBestSellingModels;
