import { GET_CLICKED_NOTIFICATION_INFO,CLEAR_CLICKED_NOTIFICATION_INFO } from "../types";

const notificaitonClicked = (notification) => {
    return {
        type : GET_CLICKED_NOTIFICATION_INFO,
        notification
    }
}

const removeNotificaitonClicked = () => {
    return {
        type : GET_CLICKED_NOTIFICATION_INFO
    }
}

export { notificaitonClicked , removeNotificaitonClicked }