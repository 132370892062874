import * as React from 'react';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PlaySwipeableViews from 'react-swipeable-views';

const SwipeableTextMobileStepper = (props) => {
  const { modelId, productImages } = props; // eslint-disable-line  no-unused-vars
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  if (productImages?.length !== 0) {
    const maxSteps = productImages?.length;
    return (
      <Box
        sx={{
          marginBottom: '4%'
        }}
      >
        <PlaySwipeableViews
          axis={theme?.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {productImages.map((step, index) => (
            <Box
              key={step?.label}
              sx={{
                display: 'block',
                overflow: 'hidden',
                width: '100%',
                height: 400,
                background: '#ffffff',
                textAlign: 'center'
              }}
            >
              {Math.abs(activeStep - index) <= 2 ? (
                step?.type?.includes('image') ? (
                  <Box component="img" src={step?.imgPath} alt={step?.label} />
                ) : (
                  <iframe width="100%" height={400} title="iframe" src={step?.imgPath}></iframe>
                )
              ) : null}
            </Box>
          ))}
        </PlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              Next
              {theme?.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme?.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      </Box>
    );
  }
};

export default SwipeableTextMobileStepper;
