const { SELECTED_PLAN, ONSHAPE_DOCUMENT, REMOVE_ONSHAPE_DOCUMENT } = require('../types');

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_PLAN: {
      return { ...state, ...action.plan };
    }
    case ONSHAPE_DOCUMENT: {
      return { ...state, ...action.documnetDetails };
    }
    case REMOVE_ONSHAPE_DOCUMENT: {
      return { ...state, ...{ documnetDetails: {} } };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
