import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { requiredMessage } from '../../utils/helpers';
import { Input as MuiInput } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ErrorMessage, FormControlBox } from '../../Components/Common';
import * as Yup from 'yup';
import axios from 'axios';
import { USER } from '../../utils/apis';
import { useSnackbar } from 'notistack';

const validatonSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(requiredMessage('Full Name'))
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets and whitespace are allowed for Full Name!')
    .matches(/^[a-zA-z]+[ ][a-zA-Z]+/, 'Atleast 1 whitespace required for fullname!'),
  email: Yup.string().trim().required(requiredMessage('Email')).email('Please enter valid email address!'),
  password: Yup.string()
    .required(requiredMessage('Password'))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,12})/i,
      'Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirmPassword: Yup.string()
    .required(requiredMessage('Confirm Password'))
    .oneOf([Yup.ref('password')], 'Passwords do not match')
});

const Signup = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState({ password: false, confirmPassword: false });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validatonSchema),
    defaultValues: {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
  });

  const handleClickShowPassword = (field) => {
    let showPasswordState = { ...showPassword, [field]: !showPassword[field] };
    setShowPassword(showPasswordState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    axios
      .post(USER.POST.REGISTER, data)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          reset();
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
          reset({
            password: '',
            confirmPassword: ''
          });
        }
      })
      .catch((e) => {
        const res = e.response;
        enqueueSnackbar(res.data.message, { variant: 'error' });
        reset({
          password: '',
          confirmPassword: ''
        });
      });
  };
  return (
    <Container
      maxWidth="sm"
      sx={{
        margin: '0 auto',
        pt: '40px'
      }}
    >
      <Typography variant="h4">Create your Spokbee account</Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          my: '40px'
        }}
      >
        <FormControlBox>
          <FormLabel>Full Name</FormLabel>
          <Controller
            name="fullName"
            control={control}
            render={({ field }) => (
              <MuiInput
                value={field?.value}
                placeholder="Your Name"
                error={errors?.fullName ? true : false}
                onChange={(value) => field?.onChange(value)}
              />
            )}
          />
          {errors.fullName && <ErrorMessage msg={errors?.fullName?.message} />}
        </FormControlBox>
        <FormControlBox>
          <FormLabel>Email</FormLabel>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <MuiInput
                value={field?.value}
                onChange={(value) => field.onChange(value)}
                placeholder="Your Email"
                error={errors?.email ? true : false}
              />
            )}
          />
          {errors.email && <ErrorMessage msg={errors?.email?.message} />}
        </FormControlBox>
        <FormControlBox>
          <FormLabel>Password</FormLabel>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <MuiInput
                value={field?.value}
                onChange={(value) => field.onChange(value)}
                type={showPassword?.password ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('password')}
                      onMouseDown={() => handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Your Password"
                error={errors?.password ? true : false}
              />
            )}
          />
          {errors.password && <ErrorMessage msg={errors?.password?.message} />}
        </FormControlBox>
        <FormControlBox>
          <FormLabel>Confirm Password</FormLabel>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <MuiInput
                value={field?.value}
                onChange={(value) => field.onChange(value)}
                type={showPassword?.confirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword('confirmPassword')}
                      onMouseDown={() => handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Confirm Your Password"
                error={errors?.password ? true : false}
              />
            )}
          />
          {errors.confirmPassword && <ErrorMessage msg={errors?.confirmPassword?.message} />}
        </FormControlBox>
        <FormControlBox>
          <Button type="submit" variant="contained">
            Register
          </Button>
          <Typography
            sx={{
              mt: '10px'
            }}
          >
            Already have account? <Link to="/login">Login</Link>
          </Typography>
        </FormControlBox>
      </Box>
    </Container>
  );
};

export default Signup;
