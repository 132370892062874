import { Box, Container, Typography, useTheme } from '@mui/material';
import { SectionTitle, SectionWrapper, SectionSubTitle } from '../Common';

// eslint-disable-next-line  no-unused-vars
const WhyChoose = (props) => {
  const theme = useTheme();
  const items = [
    {
      imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/cd1cebd3-be86-4cfb-23a5-10bd66420d00/public',
      title: 'Best Prices',
      subTitle: 'Instant Price Quoting'
    },
    {
      imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/fe0c275d-5201-4d8d-ea5a-479b2b7f9200/public',
      title: 'Biggest Selection',
      subTitle: 'Unparalleled customization'
    },
    {
      imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/c184a3ba-1ace-42d9-9ae5-517bd4030c00/public',
      title: 'Secure Payment',
      subTitle: '100% Secure Payments'
    },
    {
      imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/51f7558a-5724-4c27-7e3d-a39fce4e9f00/public',
      title: 'Best Quality',
      subTitle: 'Original Product Guaranteed'
    }
  ];

  return (
    <SectionWrapper>
      <Container>
        <SectionTitle sx={{ textAlign: 'center' }}>
          <Typography variant="span">Why Choose </Typography>
          <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
            Us
          </Typography>
          <SectionSubTitle sx={{ textAlign: 'center', margin: '40px auto', maxWidth: '100% !important' }}>
            We understand that every customer is different, which is why we give you the power to create products that are tailored
            specifically to your requirements. Whether you&apos;re looking for personalized gifts, unique home decor, or customized
            industrial supplies, all products can be customized to match your vision. So why settle for generic items when you can have
            something truly special? Start creating your dream products today!
          </SectionSubTitle>
        </SectionTitle>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: {
              xs: '15px',
              sm: '35px',
              md: '15px'
            }
          }}
        >
          {items.map((item, i) => {
            return (
              <Box
                key={`why-choose-${i}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  flexBasis: {
                    xs: '45%',
                    md: '20%'
                  }
                }}
              >
                <Box
                  className="image-wrapper"
                  sx={{
                    border: `1px solid ${theme.palette.custom.gray}`,
                    background: theme.palette.primary.dark05,
                    borderRadius: '50%',
                    width: {
                      xs: '60px',
                      sm: '100px'
                    },
                    height: {
                      xs: '60px',
                      sm: '100px'
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Box
                    component="img"
                    src={item.imageUrl}
                    sx={{
                      maxWidth: '60%'
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    marginTop: '20px',
                    fontWeight: 600,
                    fontSize: {
                      xs: '14px',
                      sm: '20px',
                      md: '22px'
                    },
                    lineHeight: 1.4,
                    color: theme.palette.secondary.main
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    marginTop: '5px',
                    fontWeight: 400,
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '16px'
                    },
                    lineHeight: 1.4,
                    color: theme.palette.secondary.main
                  }}
                >
                  {item.subTitle}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Container>
    </SectionWrapper>
  );
};

export default WhyChoose;
