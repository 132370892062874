import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// project import
import Logo from './Logo';
import { allPaths } from '../../../utils/constants';
import { activeItem } from '../../../Redux/actions/menuActions';
import { useEffect, useState } from 'react';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, to }) => {
  const { defaultId } = useSelector((state) => state.menuReducer);
  const { admin } = useSelector((state) => state.adminAuthReducer);
  const dispatch = useDispatch();
  const [dashboardPath, setDashboardPath] = useState();

  useEffect(() => {
    const dashboardPath = admin?.userRole == 1 ? allPaths.ADMIN.DASHBOARD : allPaths.SELLER.DASHBOARD;
    setDashboardPath(dashboardPath);
  }, [admin]);
  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
      to={!to ? dashboardPath : to}
      sx={sx}
    >
      <Logo />
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
