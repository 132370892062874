const { ACTIVE_ITEM, ACTIVE_COMPONENT, OPEN_DRAWER } = require('../types');

const activeItem = (openItem) => {
  return {
    type: ACTIVE_ITEM,
    openItem
  };
};

const activeComponent = (openComponent) => {
  return {
    type: ACTIVE_COMPONENT,
    openComponent
  };
};

const openDrawer = (drawerOpen) => {
  return {
    type: OPEN_DRAWER,
    drawerOpen
  };
};

export { activeItem, activeComponent, openDrawer };
