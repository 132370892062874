import { Box, Button, Dialog, DialogTitle, IconButton, MenuItem, styled, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const ErrorMessage = (props) => {
  return (
    <Typography
      sx={{
        width: '100%',
        color: '#db0f0f',
        textAlign: 'left'
      }}
    >
      {props?.msg}
    </Typography>
  );
};

export const RequiredAsterisk = (props) => {
  return (
    <Typography
      variant="span"
      sx={{
        color: '#db0f0f'
      }}
    >
      *
    </Typography>
  );
};

export const FormControlBox = styled(Box)(() => ({
  margin: '15px 0'
}));

export const BannerOverLay = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(180deg, #040404 0%, rgba(4, 4, 4, 0) 100%)',
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 0 15px 0',
  [theme.breakpoints.between('md', 'lg')]: {
    padding: '0px 0 100px 0'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0px 0 138px 0'
  }
}));

export const FooterMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: 1.5,
  color: theme.palette.secondary.main,
  marginBottom: '12px',
  minHeight: 'auto'
}));

export const MenuTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: 1.4,
  marginBottom: '15px',
  color: theme.palette.secondary.main
}));

export const SectionWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 0'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    padding: '50px 0'
  },
  [theme.breakpoints.up('md')]: {
    padding: '70px 0'
  }
}));

export const SectionTitle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '26px'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '35px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '48px'
  },
  lineHeight: 1.4,
  fontWeight: 700,
  textAlign: 'left'
}));

export const SectionTitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  rowGap: '20px',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse'
  }
}));

export const ProductExploreAll = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  border: `1px solid ${theme.palette.secondary.main}`,
  fontSize: '16px',
  lineHeight: 1.4,
  fontWeight: 700,
  color: theme.palette.secondary.main,
  maxHeight: '40px',
  textTransform: 'capitalize',
  padding: '6px 11px'
}));

export const SectionSubTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    maxWidth: '100%'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '16px',
    maxWidth: '500px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    maxWidth: '645px'
  },
  lineHeight: 1.4,
  fontWeight: 400,
  color: theme.palette.secondary.dark,
  marginTop: '15px'
}));

export const SectionDescription = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '20px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px'
  },
  lineHeight: 1.4,
  fontWeight: 400,
  color: theme.palette.custom.smoke,
  marginTop: '15px'
}));

export const HomeProductSliderWrapper = styled(Box)(() => ({
  marginTop: '40px'
}));

// eslint-disable-next-line  no-unused-vars
export const NoProducts = (props) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '30px',
        padding: '60px 0',
        color: theme.palette.primary.dark
      }}
    >
      No Products found!
    </Typography>
  );
};

export const ProductsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '30px',
  rowGap: '20px'
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
