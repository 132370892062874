import { GET_CLICKED_NOTIFICATION_INFO,CLEAR_CLICKED_NOTIFICATION_INFO } from "../types";

const reducer = (state = { notification:{id :"" ,notificationText:""} },action) => {

    switch (action.type) {
        case GET_CLICKED_NOTIFICATION_INFO:
            return { ...state, notification : action?.notification };
            break;

        case CLEAR_CLICKED_NOTIFICATION_INFO:
            return {}
           break;
    
        default:{
            return state;
        }
 
    }
}

export default reducer;