import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { requiredMessage } from '../../utils/helpers';
import { Input as MuiInput } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { ErrorMessage, FormControlBox } from '../../Components/Common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import { USER } from '../../utils/apis';
import { useSnackbar } from 'notistack';
import { allPaths } from '../../utils/constants';
import { useSelector } from 'react-redux';

const validatonSchema = Yup.object().shape({
  email: Yup.string().nullable().trim().required(requiredMessage('Email')).email('Please enter valid email address!')
});

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.adminAuthReducer);

  const location = useLocation();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validatonSchema),
    defaultValues: {
      email: '',
      userRole: 3
    }
  });

  useEffect(() => {
    if (admin && admin?.userRole == '3') {
      navigate(allPaths.MY_PROFILE);
    }
  }, [admin]);

  const onSubmit = (data) => {
    axios
      .post(USER.POST.FORGOT_PASSWORD, data)
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          reset();
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
          reset();
        }
      })
      .catch((e) => {
        const res = e.response;
        enqueueSnackbar(res.data.message, { variant: 'error' });
      });
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          margin: '0 auto',
          pt: '40px'
        }}
      >
        <Typography variant="h4">Forgot Password</Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            my: '40px'
          }}
        >
          <FormControlBox>
            <FormLabel>Email</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <MuiInput
                  value={field?.value}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Your Email"
                  error={errors?.email ? true : false}
                />
              )}
            />
            {errors.email && <ErrorMessage msg={errors?.email?.message} />}
          </FormControlBox>
          <FormControlBox>
            <Button type="submit" variant="contained">
              Submit
            </Button>
            <Typography
              sx={{
                mt: '10px'
              }}
            >
              Have account? <Link to={location.pathname.includes(allPaths.SELLERBASE) ? allPaths.SELLER.LOGIN : allPaths.LOGIN}>Login</Link>
            </Typography>
          </FormControlBox>
        </Box>
      </Container>
    </>
  );
};

export default ForgotPassword;
