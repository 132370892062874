import { useEffect, useState,useCallback } from 'react';
import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import { SectionTitle, SectionWrapper, SectionSubTitle,HomeProductSliderWrapper } from './../Common';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { allPaths } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";

import axios from 'axios';

import { POST } from '../../utils/apis';
import ProductBox from './ProductBox';
import Loader from '../MenuLayout/Loader';

const CategoriesList = (props) => {
  const theme = useTheme();
  const { categories } = props;
  const [selectCategories,setSelectCategories] = useState(null)
  const [products,setProducts] = useState([]);
  const [Filter,setFilter] = useState({
    filterBy : {
      isExclusive: true
    },
    perPage :4
  })
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
   const newCategory = categories?.map((category)=>{
      return {
        value: category?.id,
        label: category?.cat_name
      }
    })
     setSelectCategories(newCategory)
  },[categories])

  useEffect(()=>{
    getProducts()
  },[Filter])

  const getProducts = useCallback(() => {
    // let postData = {};
    // postData.filterBy = {
    //   isFeatured: true
    // };
    // postData.perPage = 4;
    setLoading(true);
    axios
      .post(POST.GET_ALL_MODELS, Filter)
      .then((res) => {
        const { data } = res;
        setProducts(data?.data || []);
      })
      .catch((e) => {
        console.log('e', e);
      })
      .finally(() => {
        setLoading(false);
      });
  });
  

  const categoryChangeHandler = (event) =>{
    setFilter({
      ...Filter,
      filterBy:{
        category: event.value
      }
    })
  }

  return (
    <SectionWrapper
      sx={{
        background: 'url(https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/631c2c25-ea17-4353-33cc-673d6413cf00/public)',
        backgroundSize: 'cover'
      }}
    >
      <Container>
        <SectionTitle sx={{ textAlign: 'center' }}>
          <Typography variant="span">Explore </Typography>
          {/* <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
            Categories{' '}
          </Typography> */}
          <Typography variant="span" sx={{ color: theme.palette.primary.dark }}>
            Products
          </Typography>
        </SectionTitle>
        <SectionSubTitle
          sx={{
            maxWidth: '100% !important',
            marginX: 'auto'
          }}
        >
          Buy fully customized products, manufactured for you on demand
        </SectionSubTitle>
        {/* <SectionSubTitle
          sx={{
            maxWidth: '100% !important',
            marginX: 'auto'
          }}
        >
          We offer a diverse and ever-expanding selection of product categories to keep things fresh and exciting
        </SectionSubTitle> */}
        <Box
          id="categories-list"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginY: '40px',
            marginTop: "30px",
            rowGap: '20px',
            gap: {
              xs: '20px',
              sm: '10px',
              md: '20px'
            },
            alignContent: 'center',
            '.categoryItem': {
              width: {
                xs: '29%',
                sm: '14%'
              }
            }
          }}
        >
          {/* {categories.map((category, i) => {
            return (
              <Box key={`category-${i}`} className="categoryItem" onClick={() => navigate(`${allPaths.PRODUCTS}/category/${category._id}`)}>
                <Box
                  sx={{
                    width: {
                      xs: '80px',
                      sm: '100px'
                    },
                    height: {
                      xs: '80px',
                      sm: '100px'
                    },
                    borderRadius: '50%',
                    border: `1px solid ${theme.palette.custom.gray}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginX: 'auto',
                    cursor: 'pointer'
                  }}
                >
                  <Box
                    component="img"
                    src={category.image_url}
                    sx={{
                      maxWidth: '40px',
                      margin: '0 auto'
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '9px',
                    color: theme.palette.secondary.main,
                    fontSize: '18px',
                    lineHeight: 1.4,
                    fontWeight: 600,
                    cursor: 'pointer'
                  }}
                >
                  <Typography>{category.cat_name}</Typography>
                  <KeyboardArrowRightIcon />
                </Box>
              </Box>
            );
          })} */}
          
          {loading && <Box sx={{display:"flex",justifyContent:"center"}}><Loader /></Box>}
          {
            loading === false && (
              <>
                 <Grid container spacing="34px">
                   {
                     products.map((prod,i) =>{
                      return <ProductBox key={`featured-${i}`} product={prod}/>
                    }) 
                   }
                 </Grid>
              </>
            )
          }
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box marginRight={1} marginTop={"4px"}>
            {/* <Button>Categories</Button> */}
            <Select
             options={selectCategories}
             isSearchable={false}
             placeholder="Categories"
             defaultValue={selectCategories?.find((category) =>category.value === "641c497da3673bd0401ac85d" )}
             onChange={(e)=>categoryChangeHandler(e)}
             styles={{
              menu: (provided) => ({
                ...provided,
                marginBottom: 0,
                marginTop: 0,
                borderRadius: "20px",
                border:"1px solid #FFB63B",
                color: theme.palette.secondary.dark
              }),
              menuList: (provided) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 0,
                border:"1px solid #FFB63B"
              }),
              container:(provided) => ({
                ...provided,
                borderRadius: "20px",
                border:"1px solid #FFB63B"
              }),
              control:(provided) => ({
                ...provided,
                borderRadius: "20px",
                border:"1px solid #FFB63B"
              })
            }}
            >
              Categories
            </Select>
          </Box>
          <Box>
           <Button
            variant="contained"
            sx={{
             borderRadius: '20px',
             backgroundColor: theme.palette.primary.dark,
             color: theme.palette.custom.white,
             padding: '8px 16px'
            }}
            onClick={() => navigate(allPaths.PRODUCTS)}
           >
            Explore All Products <KeyboardArrowRightIcon />
           </Button>
          </Box>
        </Box>
       
      </Container>
    </SectionWrapper>
  );
};

export default CategoriesList;
