// for Three.js canvas import
import {
  ACESFilmicToneMapping,
  Box3,
  CanvasTexture,
  Color,
  EquirectangularReflectionMapping,
  Fog,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  PCFSoftShadowMap,
  PerspectiveCamera,
  RepeatWrapping,
  sRGBEncoding,
  Vector2,
  Vector3,
  WebGLRenderer
} from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { FlakesTexture } from 'three/examples/jsm/textures/FlakesTexture';
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper';
import { LIBERTY_PEN_GUN, storLocModalId } from '../../utils/constants';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

const removeFromMemory = (obj) => {
  //handles gltf groups generated by onshape
  if (obj.isGroup) {
    obj.traverse(function (child) {
      // for all mesh objects in scene
      if (child.isMesh) {
        child.geometry.dispose();
        child.material.dispose();
      }
    });
  }
  //handles individual meshes
  if (obj.isMesh) {
    obj.geometry.dispose();
    obj.material.dispose();
  }
};

export const initThreeJsElements = (canvas, scene, modelId, setDataImg, isFrameComponent, isLessThen335px) => {
  const canvasElement = canvas?.current;
  let options = {};

  const uniqueTimeStamp = Date.now();

  if (canvasElement instanceof HTMLCanvasElement) {
    options.canvas = canvasElement;
    options.antialias = true;
  }
  const renderer = new WebGLRenderer(Object.assign(options, { antialias: true, preserveDrawingBuffer: true }));
  if (!options?.canvas || canvasElement == null) {
    canvasElement.innerHTML = '';
    canvasElement.appendChild(renderer?.domElement);
  } else {
    options.canvas = canvasElement;
  }

  scene.fog = new Fog(0xffffff, 0.1, 1e6);

  let camera = scene.getObjectByName('PerspectiveCamera');
  if (!camera) {
    camera = new PerspectiveCamera(35, canvasElement.clientWidth, canvasElement.clientHeight, 0.1, 1e6);
    camera.name = 'PerspectiveCamera';
    scene.add(camera);
  }

  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.outputEncoding = sRGBEncoding;
  renderer.setClearColor(scene?.fog?.color, 1);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFSoftShadowMap;

  renderer.physicallyCorrectLights = true;
  renderer.outputEncoding = sRGBEncoding;

  let controls;
  if (modelId === storLocModalId) {
    controls = new OrbitControls(camera, renderer.domElement);
  } else {
    controls = new TrackballControls(camera, renderer.domElement);
  }

  controls.maxPolarAngle = Math.PI / 2;
  controls.rotateSpeed = 1;
  controls.zoomSpeed = 1.2;
  controls.panSpeed = 0.8;
  controls.noZoom = false;
  controls.noPan = false;

  const resize = function () {
    const width = canvasElement?.clientWidth;
    const height = canvasElement?.clientHeight;
    if (canvasElement?.width !== width || canvasElement?.height !== height) {
      renderer?.setSize(width, 410, false);
      camera.aspect = width / height;
      camera?.updateProjectionMatrix();
    }
  };

  /*const loader = new TextureLoader()
      const albedoMap = loader.load('/textures/TexturesCom_Concrete_Polished_1K_albedo.png')
      const normalMap = loader.load('/textures/TexturesCom_Concrete_Polished_1K_normal.png')
      const roughnessMap = loader.load('/textures/TexturesCom_Concrete_Polished_1K_roughness.png')

      const planeMaterial = new MeshStandardMaterial({
          map: albedoMap,
          normalMap: normalMap,
          roughnessMap: roughnessMap,
          roughness: 1
      })*/

  /**
   * Apply an operation to all mesh children of the given element.
   *
   * @param {object} object The parent node whose children will be operated upon.
   * @param {Function<object,void>} callback The function to operate on the nodes.
   */
  // eslint-disable-next-line  no-unused-vars
  const traverseMaterials = (object, callback) => {
    object.traverse((node) => {
      if (!node.isMesh) return;
      const materials = Array.isArray(node?.material) ? node?.material : [node?.material];
      materials?.forEach(callback);
    });
  };

  /**
   * Sets the contents of the scene to the given GLTF data.
   *
   * @param {object} gltfScene The GLTF data to render.
   */
  const setGltfContents = (gltfScene, material) => {
    if (gltfScene) {
      // Rotate gltf scene to be oriented correctly on load
      gltfScene.rotation.x -= 1.57;
      const box = new Box3().setFromObject(gltfScene);
      const size = box?.getSize(new Vector3()).length();
      const center = box?.getCenter(new Vector3());

      controls?.reset();

      gltfScene.position.x += gltfScene?.position?.x - center?.x;
      gltfScene.position.y += gltfScene?.position?.y - center?.y;
      gltfScene.position.z += gltfScene?.position?.z - center?.z;

      controls.maxDistance = (modelId == LIBERTY_PEN_GUN && isFrameComponent && !isLessThen335px) ? size : size * 10;
      camera.near = size / 100;
      camera.far = size * 100;
      camera.updateProjectionMatrix();
      camera.position.copy(center);
      const boxSize = box.getSize(new Vector3());
      // Set the camera position based on the maximum dimension so the user can get a clear view of the front regardless of model dimensions
      const maxDim = Math.max(Math.max(boxSize?.x, boxSize?.y), boxSize?.z);
      if (modelId === storLocModalId) {
        camera.position.x = 0;
        camera.position.y = maxDim * 0.2;
        camera.position.z = maxDim * 1.5;
        camera.lookAt(-center);
      } else {
        camera.position.x = maxDim * 2;
        camera.position.y = maxDim * 1.5;
        camera.position.z = maxDim * 2;
        camera.lookAt(center);
      }

      gltfScene.name = 'gltf_scene';
      scene.add(gltfScene);

      controls.update();

      // Update textures
      // traverseMaterials(gltfScene, (material) => {
      //     if (material.map) material.map.encoding = sRGBEncoding
      //     if (material.emissiveMap) material.emissiveMap.encoding = sRGBEncoding
      //     if (material.map || material.emissiveMap) material.needsUpdate = true
      // })
      // const loader = new TextureLoader()
      // const baseColorMap = loader.load('/textures/Wood07_2k_BaseColor.png')
      // baseColorMap.repeat = new Vector2(5,5)

      gltfScene.traverse((child) => {
        // console.log(child);
        if (child?.isMesh) {
          const roughnessMipmapper = new RoughnessMipmapper(renderer);
          roughnessMipmapper?.generateMipmaps(child?.material);

          if (material?.texture === 'multicolor') {
            //checking composite part name for price for storloc only
            // if (child.parent.name.charAt(0) === "$") {
            //   const updatedPrice = child.parent.name.slice(
            //     0,
            //     child.parent.name.indexOf("_")
            //   );
            //   // console.log(updatedPrice, productPrice);
            //   if (updatedPrice != productPrice) {
            //     setProductPrice(updatedPrice);
            //   }
            // }
            //maps existing gltf materials to corresponding materialColors
            /*var color = child.material.color
            for(let i = 0; i < materialRef?.current?.default_colors?.length; i++){
                const defaultColor = new Color(materialRef?.current?.default_colors[i])
                if(Math.abs(defaultColor.r - color.r) < 0.001 && Math.abs(defaultColor.g - color.g) < 0.001 && Math.abs(defaultColor.b - color.b) < 0.001){
                    if (i in materialColors) {
                        child.material.color = materialColors[i]
                    }
                }
            }*/

            //stor-loc
            child.material.metalness = 1.0;
            child.material.roughness = 0.5;
            child.material.clearcoat = 0.2;
            child.material.clearcoatRoughness = 0.1;

            //currently casting shadow for stor loc multicolor
            child.castShadow = true;
          }

          if (material?.texture === 'translucent_plastic') {
            child?.material?.dispose();
            child.material = new MeshPhysicalMaterial({
              transmission: 1,
              thickness: 1.2, // Add refraction!
              roughness: 0.6,
              clearcoat: 1,
              clearcoatRoughness: 0.3,
              color: new Color(`${material?.color}`)
            });
          }

          if (material?.texture === 'matte_plastic') {
            child?.material?.dispose();
            child.material = new MeshPhysicalMaterial({
              thickness: 2.5, // Add refraction!
              roughness: 1.0,
              color: new Color(`${material?.color}`)
            });
          }

          if (material?.texture === 'clear_plastic') {
            child?.material?.dispose();
            child.material = new MeshPhysicalMaterial({
              transmission: 1,
              thickness: 2.5, // Add refraction!
              roughness: 0.2,
              ior: 1.5,
              reflectivity: 0.8,
              clearcoat: 1,
              clearcoatRoughness: 0.1,
              color: new Color(`${material?.color}`)
            });
          }

          if (material?.texture === 'plastic') {
            const normalMap3 = new CanvasTexture(new FlakesTexture());
            normalMap3.wrapS = RepeatWrapping;
            normalMap3.wrapT = RepeatWrapping;
            normalMap3.repeat.x = 10;
            normalMap3.repeat.y = 6;
            normalMap3.anisotropy = 16;

            child.material = new MeshPhysicalMaterial({
              clearcoat: 0.2,
              clearcoatRoughness: 0.1,
              metalness: 0,
              roughness: 1.0,
              color: new Color(`${material?.color}`),
              normalMap: normalMap3,
              normalScale: new Vector2(0.15, 0.15)
            });
          }

          if (material?.texture === 'gold' || material?.texture === 'silver' || material?.texture === 'steel') {
            child.material = new MeshStandardMaterial({
              color: material?.color,
              metalness: 1.0,
              roughness: 0.1
            });
            // child.material = new MeshPhysicalMaterial({
            //   map:baseColorMap
            // })
          }

          if (material?.texture === 'matte_metal') {
            child.material = new MeshStandardMaterial({
              color: material?.color,
              metalness: 0.7,
              roughness: 0.3
            });
          }

          if (material?.texture === 'onshapeColorOptions') {
            child.material.metalness = 1.0;
            child.material.roughness = 0.5;
            child.material.clearcoat = 0.2;
            child.material.clearcoatRoughness = 0.1;
            child.castShadow = true;
          }
        } else {
          if (!child?.isMesh) return;
        }
      });
    }
  };

  /**
   * Animate the scene.
   */
  //let counter = 0;
  const animate = () => {
    resize();
    renderer?.render(scene, camera);
    requestAnimationFrame(animate);
    controls?.update();
    // if (counter % 200 === 0) {
    //   console.debug('renderer id: ', uniqueTimeStamp);
    // }
    // counter++;
  };
  requestAnimationFrame(animate);

  return {
    /**
     * Parse and load the given GLTF data, and trigger rendering.
     *
     * @param {object} gltfData The GLTF data to be rendered.
     */
    loadGltf: (gltfData, material) => {
      // Remove existing GLTF scene from the scene
      const existingGltfScene = scene.getObjectByName('gltf_scene');
      if (existingGltfScene) {
        removeFromMemory(existingGltfScene);
        scene.remove(existingGltfScene);
        //renderer.renderLists.dispose()
      }

      const textures = {
        steel: 'skylit_garage_2k.hdr',
        gold: 'subway_entrance_2k.hdr',
        silver: 'empty_warehouse_01_2k.hdr',
        matte_metal: 'thatch_chapel_2k.hdr',
        plastic: 'kiara_interior_2k.hdr',
        translucent_plastic: 'empty_warehouse_01_2k.hdr',
        matte_plastic: 'empty_warehouse_01_2k.hdr',
        clear_plastic: 'empty_warehouse_01_2k.hdr',
        multicolor: 'empty_warehouse_01_2k.hdr',
        onshapeColorOptions: 'empty_warehouse_01_2k.hdr'
      };
      new RGBELoader().load(`/textures/${textures[`${material?.texture}`]}`, function (texture) {
        texture.mapping = EquirectangularReflectionMapping;
        scene.environment = texture;
        //only show background for multicolor storloc
        if (material?.texture === 'multicolor') {
          scene.background = texture;
        }

        const gltfLoader = new GLTFLoader();

        //set up the DRACO decoder
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/gltf/');
        gltfLoader.setDRACOLoader(dracoLoader);

        gltfLoader.parse(
          gltfData,
          '',
          (gltf) => {
            // onLoad
            document.body.style.cursor = 'default';
            const gltfScene = gltf?.scene || gltf?.scenes[0];
            setGltfContents(gltfScene, material);
            animate();
            const cav = document.querySelector('#canvas-wrapper canvas');
            const base64 = cav.toDataURL('image/png');

            const cropAndMakeSquare = (base64Image, setDataImg, modelId, storLocModalId) => {
              if (modelId !== storLocModalId) {
                const img = new Image();
                img.src = base64Image;

                img.onload = () => {
                  const originalWidth = img.width;
                  const originalHeight = img.height;
                  const squareSize = Math.min(originalWidth, originalHeight);

                  const tempCanvas = document.createElement('canvas');
                  const tempCtx = tempCanvas.getContext('2d');

                  tempCanvas.width = squareSize;
                  tempCanvas.height = squareSize;

                  const startX = (originalWidth - squareSize) / 2;
                  const startY = (originalHeight - squareSize) / 2;

                  tempCtx.drawImage(img, startX, startY, squareSize, squareSize, 0, 0, squareSize, squareSize);

                  const croppedBase64 = tempCanvas.toDataURL('image/png');
                  setDataImg(croppedBase64);
                };
              } else {
                setDataImg(base64Image);
              }
            };

            cropAndMakeSquare(base64, setDataImg, modelId, storLocModalId);

          },
          (err) => {
            // onError
            console.log(`Error loading GLTF: ${err}`);
          }
        );
      });
    }
  };
};
