// assets
import { MdDashboard, MdAttachMoney, MdShoppingCart  } from "react-icons/md";
import { AiFillProduct } from "react-icons/ai";
import { LuFileBox } from "react-icons/lu";
import { allPaths } from '../../utils/constants';

// // icons
// const icons = {
//   DashboardOutlined,
//   DollarOutlined
// };

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const admin = {
  id: 'main-menu',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: allPaths.ADMIN.DASHBOARD,
      icon: MdDashboard,
      breadcrumbs: false
    },
    {
      id: 'plans',
      title: 'Subscription Plans',
      type: 'item',
      url: allPaths.ADMIN.SUBSCCRIPTION_PLANS,
      icon: MdAttachMoney,
      breadcrumbs: true
    },
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: allPaths.ADMIN.PRODUCTS,
      icon: AiFillProduct,
      breadcrumbs: true
    },
    {
      id: 'orders',
      title: 'Orders',
      type: 'item',
      url: allPaths.ADMIN.ORDERS,
      icon: MdShoppingCart,
      breadcrumbs: true,
    },
    {
      id: 'elements',
      title: 'Blob Elements',
      type: 'item',
      url: allPaths.ADMIN.ELEMENTS,
      icon: LuFileBox,
      breadcrumbs: true,
    }
  ]
};

export default admin;