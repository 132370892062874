import { LOGIN_ADMIN, REMOVE_ADMIN } from '../types';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_ADMIN: {
      return { ...state, admin: action.admin };
    }
    case REMOVE_ADMIN: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default reducer;
