import { Box, Dialog, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LayoutEmpty from '../../assets/storLoc/layouts/LayoutEmpty.jpg';
import LayoutEmptyLinear from '../../assets/storLoc/layouts/LayoutEmptyLinear.jpg';
import Layout1 from '../../assets/storLoc/layouts/Layout1.png';
import Layout2 from '../../assets/storLoc/layouts/Layout2.png';
import Layout3 from '../../assets/storLoc/layouts/Layout3.png';
import Layout4 from '../../assets/storLoc/layouts/Layout4.png';
import Layout5 from '../../assets/storLoc/layouts/Layout5.png';
import Layout6 from '../../assets/storLoc/layouts/Layout6.png';
import Layout7 from '../../assets/storLoc/layouts/Layout7.png';
import Layout8 from '../../assets/storLoc/layouts/Layout8.png';
import Layout9 from '../../assets/storLoc/layouts/Layout9.png';

// eslint-disable-next-line  no-unused-vars
const StyledLayoutOpt = styled(Box)(({ theme }) => ({
  maxWidth: '84%'
}));

const LayoutDialog = (props) => {
  const { open, handleClose, selectLayoutForDrawer, changeDrawerLayout, StorLocNewSchema } = props;

  const LayoutBox = (layoutProps) => {
    // console.log(layoutProps);
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          cursor: 'pointer',
          justifyContent: 'center',
          fontSize: '0.6rem',
          width: '19%',
          alignItems: 'center'
        }}
      >
        <StyledLayoutOpt
          component="img"
          src={layoutProps.layoutImg}
          onClick={() => changeDrawerLayout(layoutProps.layout, layoutProps.drawer)}
          sx={{
            boxShadow: 2
          }}
        />
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '0.6rem'
          }}
        >
          {layoutProps.name}
        </Typography>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      disableEscapeKeyDown={true}
      container={() => document.getElementById('canvas-wrapper')}
      sx={{
        position: 'absolute',
        right: 0,
        left: 'auto',
        width: '65%',
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        },
        '& .MuiDialog-paper': {
          marginRight: '0px',
          marginBottom: '25px'
        }
      }}
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          padding: '7px',
          fontWeight: '600',
          textAlign: 'center',
          fontSize: '1.2rem'
        }}
      >
        {`Drawer(${selectLayoutForDrawer}) Layout`}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 2,
              top: 2
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingY: '10px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 1
          }}
        >
          <LayoutBox layoutImg={LayoutEmpty} name="Empty" layout="Empty" drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={LayoutEmptyLinear} name="Empty with Liner" layout={StorLocNewSchema ? 0 : "Empty with Liner"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout1} name="Layout 1" layout={StorLocNewSchema ? 1 : "Layout 1"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout2} name="Layout 2" layout={StorLocNewSchema ? 2 : "Layout 2"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout3} name="Layout 3" layout={StorLocNewSchema ? 3 : "Layout 3"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout4} name="Layout 4" layout={StorLocNewSchema ? 4 : "Layout 4"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout5} name="Layout 5" layout={StorLocNewSchema ? 5 : "Layout 5"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout6} name="Layout 6" layout={StorLocNewSchema ? 6 : "Layout 6"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout7} name="Layout 7" layout={StorLocNewSchema ? 7 : "Layout 7"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout8} name="Layout 8" layout={StorLocNewSchema ? 8 : "Layout 8"} drawer={selectLayoutForDrawer - 1} />
          <LayoutBox layoutImg={Layout9} name="Layout 9" layout={StorLocNewSchema ? 9 : "Layout 9"} drawer={selectLayoutForDrawer - 1} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LayoutDialog;
