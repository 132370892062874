const HOME = '/';
const ABOUT = '/about-us';
const FAQS = '/faqs';
const CONTACT = '/contact-us';
const TERMS_CONDITIONS = `/terms`;
const PRIVACY_POLICY = `/privacy-policy`;
const EDITOR = '/editor';
const FRAME = '/frame'
const STORE = '/store';
const PRICING = '/pricing';
const LOGIN = '/login';
const SIGNUP = '/register';
const FORGOT_PASSWORD = '/forgot-password';
const RESET_PASSWORD = '/reset-password';
const UPDATE_PASSWORD = '/update-password';
const MY_PROFILE = `/my-profile`;
const PRODUCTS = `/products`;
const SELL_ON_SPOKBEE = `/sell-on-spokbee`;
const ONSHAPE_CALLBACK = '/onshape/callback';
const ONSHAPE_GRANT_ACCESS = '/onshape/grant_access';
const TUTORIALANDRESOURCES = '/tutorial';

// May not used
const SETTINGS = '/settings';
const HELP = '/help';
const TRANSFER = '/transfer';
const FORMC = '/formc';
const COMPANY = '/company';
const COMPANY_LOGIN = `${COMPANY}/login`;
const COMPANY_REGISTER = `${COMPANY}/register`;
const INVITE = '/invite/:token';
// may not used over

const SELLERBASE = '/seller';
const ADMINBASE = '/admin';
const ADMIN = {
  LOGIN: `${ADMINBASE}/login`,
  // SETTINGS: `${ADMINBASE}/settings`, may be unused
  FORGOT_PASSWORD: `${ADMINBASE}/forgot-password`,
  RESET_PASSWORD: `${ADMINBASE}/reset-password`,
  CHANGE_PASSWORD: `${ADMINBASE}/update-password`,
  DASHBOARD: `${ADMINBASE}/dashboard`,
  PROFILE: `${ADMINBASE}/profile`,
  SUBSCCRIPTION_PLANS: `${ADMINBASE}/plans`,
  PRODUCTS: `${ADMINBASE}/products`,
  ADD_PRODUCTS: `${ADMINBASE}/products/add`,
  EDIT_PRODUCTS: `${ADMINBASE}/products/edit/:id`,
  PREVIEW_PRODUCTS: `${ADMINBASE}/products/preview/:id`,
  ORDERS: `${ADMINBASE}/orders`,
  ELEMENTS: `${ADMINBASE}/elements`
};
const SELLER = {
  REGISTER: `${SELLERBASE}/register`,
  LOGIN: `${SELLERBASE}/login`,
  RESET_PASSWORD: `${SELLERBASE}/reset-password`,
  NEW_PASSWORD: `${SELLERBASE}/new-password`,
  FORGOT_PASSWORD: `${SELLERBASE}/forgot-password`,
  MY_PROFILE: `${SELLERBASE}/profile`,
  UPDATE_PASSWORD: `${SELLERBASE}/update-password`,
  DASHBOARD: `${SELLERBASE}/dashboard`,
  PRODUCTS: `${SELLERBASE}/products`,
  ADD_PRODUCTS: `${SELLERBASE}/products/add`,
  EDIT_PRODUCTS: `${SELLERBASE}/products/edit/:id`,
  PREVIEW_PRODUCTS: `${SELLERBASE}/products/preview/:id`,
  CREATE_LABEL: `${SELLERBASE}/create-label`,
  ORDERS: `${SELLERBASE}/orders`,
  TUTORIAL_AND_RESOURCES: TUTORIALANDRESOURCES,
  USERS: `${SELLERBASE}/users`,
  ADDUSER: `${SELLERBASE}/users/add`,
  EDITUSER: `${SELLERBASE}/users/edit/:id`
};

const SHIPPO_SELLER_BASE_URL = `/shippo/seller`;
const SHIPPO_SELLER = {
  REGISTER: `${SHIPPO_SELLER_BASE_URL}/register`,
  LOGIN: `${SHIPPO_SELLER_BASE_URL}/login`,
  RESET_PASSWORD: `${SHIPPO_SELLER_BASE_URL}/reset-password`,
  NEW_PASSWORD: `${SHIPPO_SELLER_BASE_URL}/new-password`,
  FORGOT_PASSWORD: `${SHIPPO_SELLER_BASE_URL}/forgot-password`,
  MY_PROFILE: `${SHIPPO_SELLER_BASE_URL}/profile`,
  UPDATE_PASSWORD: `${SHIPPO_SELLER_BASE_URL}/update-password`,
  DASHBOARD: `${SHIPPO_SELLER_BASE_URL}/dashboard`,
  PRODUCTS: `${SHIPPO_SELLER_BASE_URL}/products`,
  ADD_PRODUCTS: `${SHIPPO_SELLER_BASE_URL}/products/add`,
  EDIT_PRODUCTS: `${SHIPPO_SELLER_BASE_URL}/products/edit/:id`,
  CREATE_LABEL: `${SHIPPO_SELLER_BASE_URL}/create-label`,
  ORDERS: `${SHIPPO_SELLER_BASE_URL}/orders`
};

const CUSTOMER_BASE_URL = "/customer"
const CUSTOMER = {
  REGISTER: `${CUSTOMER_BASE_URL}/register`,
  LOGIN: `${CUSTOMER_BASE_URL}/login`,
  DASHBOARD: `${CUSTOMER_BASE_URL}/dashboard`,
  ORDERS: `${CUSTOMER_BASE_URL}/orders`
}

export default {
  HOME,
  ABOUT,
  FAQS,
  CONTACT,
  TERMS_CONDITIONS,
  PRIVACY_POLICY,
  EDITOR,
  PRICING,
  PRODUCTS,
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  MY_PROFILE,
  UPDATE_PASSWORD,
  SETTINGS,
  HELP,
  TRANSFER,
  FORMC,
  ADMIN,
  STORE,
  COMPANY,
  COMPANY_LOGIN,
  COMPANY_REGISTER,
  SELL_ON_SPOKBEE,
  ONSHAPE_CALLBACK,
  ONSHAPE_GRANT_ACCESS,
  SELLERBASE,
  ADMINBASE,
  SELLER,
  SHIPPO_SELLER,
  SHIPPO_SELLER_BASE_URL,
  INVITE,
  FRAME,
  CUSTOMER
};
