const depthOptions = [
  {
    id: 'S',
    value: 'Standard Depth 27 3/4in (24 1/2in usable)',
    label: 'Standard Depth 27 ¾" (24 ½" usable)',
    inchValue: 27.75
  },
  {
    id: 'SH',
    value: 'Shallow Depth 21 1/2in (18 1/4in usable)',
    label: 'Shallow Depth 21 ½" (18 ¼" usable)',
    inchValue: 21.5
  },
  {
    id: 'SS',
    value: 'Shallow Shallow Depth 18 1/4in (15in usable)',
    label: 'Shallow Shallow Depth 18 ¼" (15" usable)',
    inchValue: 18.25
  },
  {
    id: 'H',
    value: 'Half Depth 15 1/8in (11 7/8in usable)',
    label: 'Half Depth 15 1/8" (11 7/8" usable)',
    inchValue: 15.125
  }
];
const risersOptions = [
  {
    value: 'None',
    label: 'None',
    inchValue: 0
  },
  {
    value: '12 inches',
    label: '12 inches',
    inchValue: 12
  },
  {
    value: '15 inches',
    label: '15 inches',
    inchValue: 15
  },
  {
    value: '18 inches',
    label: '18 inches',
    inchValue: 18,
    isDefault: true
  },
  {
    value: '24 inches',
    label: '24 inches',
    inchValue: 24
  },
  {
    value: '30 inches',
    label: '30 inches',
    inchValue: 30
  },
  {
    value: '36 inches',
    label: '36 inches',
    inchValue: 36
  }
];

const columnsWidthOptions = [
  {
    id: 'NN',
    value: 'Narrow Narrow Wide 20in (15in usable)',
    label: 'Narrow Narrow Wide 20"(15" usable)',
    inchValue: 20
  },
  {
    id: 'N',
    value: 'Narrow Wide 23in (18in usable)',
    label: 'Narrow Wide 23" (18" usable)',
    inchValue: 23
  },
  {
    id: 'S',
    value: 'Standard Wide 29 1/4in (24 1/2in usable)',
    label: 'Standard Wide 29 ¼" (24 ½" usable)',
    isDefault: true,
    inchValue: 29.25
  },
  {
    id: 'SP',
    value: 'Standard Wide Plus 32 1/2in (28in usable)',
    label: 'Standard Wide Plus 32 ½" (28" usable)',
    inchValue: 32.5
  },
  {
    id: 'E',
    value: 'Extra Wide 43 1/4in (39in usable)',
    label: 'Extra Wide 43 ¼" (39" usable)',
    inchValue: 43.25
  },
  {
    id: 'EP',
    value: 'Extra Wide Plus 46 1/4in (42in usable)',
    label: 'Extra Wide Plus 46 ¼" (42" usable)',
    inchValue: 46.25
  },
  {
    id: 'I',
    value: 'Semi Wide 52in (47 1/2in usable)',
    label: 'Semi Wide 52" (47 ½" usable)',
    inchValue: 52
  }
];

const topOptions = [
  {
    id: 'None',
    value: 'None',
    label: 'None',
    isDefault: true
  },
  {
    id: 'Maple',
    value: 'Maple',
    label: 'Maple'
  },
  {
    id: 'Stainless',
    value: 'Stainless Steel Capped',
    label: 'Stainless Steel Capped'
  },
  {
    id: 'Capped',
    value: 'Stainless Steel Capped Maple',
    label: 'Stainless Steel Capped Maple'
  }
];

const drawerHeightOptions = [
  {
    id: 80,
    value: '80 (2in usable)',
    label: '80 (2" usable)'
  },
  {
    id: 100,
    value: '100 (3in usable)',
    label: '100 (3" usable)'
  },
  {
    id: 120,
    value: '120 (3 7/8in usable)',
    label: '120 (3 7/8" usable)'
  },
  {
    id: 140,
    value: '140 (4 3/4in usable)',
    label: '140 (4 3/4" usable)'
  },
  {
    id: 160,
    value: '160 (5 1/2in usable)',
    label: '160 (5 1/2" usable)',
    isDefault: true
  },
  {
    id: 180,
    value: '180 (6 1/8in usable)',
    label: '180 (6 1/8" usable)'
  },
  {
    id: 200,
    value: '200 (7in usable)',
    label: '200 (7" usable)'
  },
  {
    id: 220,
    value: '220 (7 3/4in usable)',
    label: '220 (7 3/4" usable)'
  },
  {
    id: 240,
    value: '240 (8 1/2in usable)',
    label: '240 (8 1/2" usable)'
  },
  {
    id: 260,
    value: '260 (9 3/4in usable)',
    label: '260 (9 3/4" usable)'
  },
  {
    id: 280,
    value: '280 (10 1/4in usable)',
    label: '280 (10 1/4" usable)'
  },
  {
    id: 300,
    value: '300 (11in usable)',
    label: '300 (11" usable)'
  },
  {
    id: 320,
    value: '320 (11 7/8in usable)',
    label: '320 (11 7/8" usable)'
  },
  {
    id: 340,
    value: '340 (12 3/4in usable)',
    label: '340 (12 3/4" usable)'
  },
  {
    id: 360,
    value: '360 (13 1/2in usable)',
    label: '360 (13 1/2" usable)'
  }
];

const drawerLayoutOptions = [
  {
    id: 'empty',
    label: 'empty'
  },
  {
    id: 0,
    label: 'Empty with Liner'
  },
  {
    id: 1,
    label: 'Layout 1'
  },
  {
    id: 2,
    label: 'Layout 2'
  },
  {
    id: 3,
    label: 'Layout 3'
  },
  {
    id: 4,
    label: 'Layout 4'
  },
  {
    id: 5,
    label: 'Layout 5'
  },
  {
    id: 6,
    label: 'Layout 6'
  },
  {
    id: 7,
    label: 'Layout 7'
  },
  {
    id: 8,
    label: 'Layout 8'
  },
  {
    id: 9,
    label: 'Layout 9'
  }
];

const BestSellingModels = [
  {
    model: 'MM1',
    configuration: {
      Height: 600,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '18 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 6, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: true, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '000004' },
      DColor: { Color: '820000' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/03449bce-eab3-4414-dd22-530badb54b00/public',
    description:
      'Mobile tool storage with 8 drawers, stainless steel surface and riser. Designed for tool storage in a workstation application. 38¾”H x 54”W x 30”D'
  },
  {
    model: 'MM2',
    configuration: {
      Height: 760,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '18 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 6, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: true, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '000004' },
      DColor: { Color: '820000' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/d7e73999-f5c1-44d2-838e-6d87d984f600/public',
    description:
      'Mobile tool storage with 11 drawers, stainless steel surface and riser. Designed for tool storage in a workstation application. 44½”H x 60”W x 30”D'
  },
  {
    model: 'MM3',
    configuration: {
      Height: 1060,
      Top: { Top: 'Stainless Steel Capped' },
      Risers: { RiserHeight: 'None' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 6, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: true, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 14, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 15, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 16, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '000004' },
      DColor: { Color: '820000' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/e9bec4e5-e4b4-427b-e759-99436ddda400/public',
    description:
      'Mobile cabinet providing maximum tool storage with a stainless steel top. Unit has 15 standard-wide drawers and I double-wide drawer. 55¼”H x 58¼”W x 30”D'
  },
  {
    model: 'MM10',
    configuration: {
      Height: 700,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '18 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 1, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 2, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 3, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 4, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 5, double: true, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 14, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '000004' },
      DColor: { Color: '820000' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/caf76877-bf01-4c94-d5c6-5c674fd48c00/public',
    description:
      'Mobile cabinet providing maximum tool storage with a stainless steel top. Unit has 13 standard-wide drawers and I double-wide drawer. 43”H x 90”W x 30”D'
  },
  {
    model: 'MM11',
    configuration: {
      Height: 700,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '18 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 1, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 2, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 3, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 4, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 5, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: true, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: true, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 14, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 15, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 16, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 17, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 18, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 19, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 20, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '820000' },
      DColor: { Color: '303039' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/558714a7-10a9-46be-35bb-a681d4cf7300/public',
    description:
      'Mobile cabinet providing maximum tool storage with a stainless steel top. Unit has 18 standard-wide drawers and 2 double-wide drawers. 43”H x 90”W x 30”D'
  },
  {
    model: 'MM14',
    configuration: {
      Height: 700,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '18 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide Plus 32 1/2in (28in usable)' } },
        { width: { width: 'Standard Wide Plus 32 1/2in (28in usable)' } },
        { width: { width: 'Standard Wide Plus 32 1/2in (28in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 1, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 2, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 3, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 4, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 5, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 14, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 15, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '002885' },
      DColor: { Color: '002885' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/460b6bfc-e948-43ed-14aa-37adb4ba7300/public',
    description:
      'Mobile tool storage with 15 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 100”W x 30”D'
  },
  {
    model: 'MM20',
    configuration: {
      Height: 700,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '18 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 1, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 2, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 3, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 4, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 5, double: true, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 14, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 15, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 16, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 17, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 18, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 19, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '000004' },
      DColor: { Color: '820000' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/270cee06-4034-45bc-8e8b-77f7e2430200/public',
    description:
      'Mobile tool storage with 19 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 120”W x 30”D'
  },
  {
    model: 'MM33',
    configuration: {
      Height: 700,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '24 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Narrow Wide 23in (18in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 1, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 2, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 3, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 4, double: true, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 5, double: true, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '180 (6 1/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '180 (6 1/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 14, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 15, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 16, double: true, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 17, double: true, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 18, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 19, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '002885' },
      DColor: { Color: 'EBECE7' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/e0d2a14e-d4bb-46f4-59dd-c4db73d08400/public',
    description:
      'Mobile tool storage with 18 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 144”W x 30”D'
  },
  {
    model: 'MM40',
    configuration: {
      Height: 700,
      Top: { Top: 'Stainless Steel Capped Maple' },
      Risers: { RiserHeight: '24 inches' },
      Wheels: true,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Extra Wide Plus 46 1/4in (42in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } },
        { width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }
      ],
      Drawers: [
        { arrayPointIndex: 1, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 2, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 3, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 4, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 5, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: true, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 14, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 15, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 16, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 17, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 18, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 19, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 20, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 21, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 22, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 23, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 24, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 25, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 26, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 27, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '000004' },
      DColor: { Color: '820000' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/11a4ed49-50a0-4c10-ba03-e6c2d79c0f00/public',
    description:
      'Mobile tool storage with 23 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 168”W x 30”D'
  },
  {
    model: 'SW700-041',
    configuration: {
      Height: 700,
      Top: { Top: 'Stainless Steel Capped' },
      Risers: { RiserHeight: 'None' },
      Wheels: false,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [{ width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }],
      Drawers: [
        { arrayPointIndex: 10, double: false, height: { height: '300 (11in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '100 (3in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '002885' },
      DColor: { Color: '002885' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/aab89e6d-8bd2-43eb-659b-92470c049e00/public',
    description: 'Bench height tool storage with 4 drawers, 33 1/4”H x 29 1/4”W x 27 3/4”D.'
  },
  {
    model: 'SW900-071',
    configuration: {
      Height: 900,
      Top: { Top: 'Stainless Steel Capped' },
      Risers: { RiserHeight: 'None' },
      Wheels: false,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [{ width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }],
      Drawers: [
        { arrayPointIndex: 10, double: false, height: { height: '240 (8 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 5, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '80 (2in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '002885' },
      DColor: { Color: '002885' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/aa79275e-0c45-4aca-07cc-07034dc92500/public',
    description: 'Counter Height (seated) tool storage with 7 drawers, 41 1/2”H x 29 1/4”W x 27 3/4”D.'
  },
  {
    model: 'SW1360-091',
    configuration: {
      Height: 1360,
      Top: { Top: 'Stainless Steel Capped' },
      Risers: { RiserHeight: 'None' },
      Wheels: false,
      Depth: { depth: 'Standard Depth 27 3/4in (24 1/2in usable)' },
      Columns: [{ width: { width: 'Standard Wide 29 1/4in (24 1/2in usable)' } }],
      Drawers: [
        { arrayPointIndex: 5, double: true, height: { height: '200 (7in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 6, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 7, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 8, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 9, double: false, height: { height: '160 (5 1/2in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 10, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 11, double: false, height: { height: '140 (4 3/4in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 12, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } },
        { arrayPointIndex: 13, double: false, height: { height: '120 (3 7/8in usable)' }, layout: { Layout: 'Empty' } }
      ],
      FColor: { Color: '000004' },
      DColor: { Color: '820000' }
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/e94af9ac-44d2-4806-6c51-e942f7e90100/public',
    description: 'Eye level height tool storage with 9 drawers, 59 1/2”H x 29 1/4”W x 27 3/4”D.'
  }
];

const newSchemaBestSellingModels = [
  {
    model: 'MM1',
    configuration: {
      Height: 600,
      Top: 'Stainless',
      RiserHeight: 18,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S'],
      Drawers: [
        { d: true, h: 120, l: 'Empty' },

        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },


        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' },

      ],
      FColor: '820000',
      DColor: '000004'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/03449bce-eab3-4414-dd22-530badb54b00/public',
    description:
      'Mobile tool storage with 8 drawers, stainless steel surface and riser. Designed for tool storage in a workstation application. 38¾”H x 54”W x 30”D'
  },
  {
    model: 'MM2',
    configuration: {
      Height: 760,
      Top: 'Stainless',
      RiserHeight: 18,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S'],
      Drawers: [
        { d: true, h: 120, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' }
      ],
      FColor: '000004',
      DColor: '820000'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/d7e73999-f5c1-44d2-838e-6d87d984f600/public',
    description:
      'Mobile tool storage with 11 drawers, stainless steel surface and riser. Designed for tool storage in a workstation application. 44½”H x 60”W x 30”D'
  },
  {
    model: 'MM3',
    configuration: {
      Height: 1060,
      Top: 'Capped',
      RiserHeight: 0,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S'],
      Drawers: [
        { d: true, h: 120, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' }
      ],
      FColor: '820000',
      DColor: '820000'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/e9bec4e5-e4b4-427b-e759-99436ddda400/public',
    description:
      'Mobile cabinet providing maximum tool storage with a stainless steel top. Unit has 15 standard-wide drawers and I double-wide drawer. 55¼”H x 58¼”W x 30”D'
  },
  {
    model: 'MM10',
    configuration: {
      Height: 700,
      Top: 'Capped',
      RiserHeight: 18,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S', 'S'],
      Drawers: [
        { d: true, h: 120, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' }
      ],
      FColor: '820000',
      DColor: '000004'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/caf76877-bf01-4c94-d5c6-5c674fd48c00/public',
    description:
      'Mobile cabinet providing maximum tool storage with a stainless steel top. Unit has 13 standard-wide drawers and 1 double-wide drawer. 43”H x 90”W x 30”D'
  },
  {
    model: 'MM11',
    configuration: {
      Height: 700,
      Top: 'Capped',
      RiserHeight: 18,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S', 'S'],
      Drawers: [
        { d: true, h: 80, l: 'Empty' },
        { d: true, h: 140, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' }
      ],
      FColor: '820000',
      DColor: '820000'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/558714a7-10a9-46be-35bb-a681d4cf7300/public',
    description:
      'Mobile cabinet providing maximum tool storage with a stainless steel top. Unit has 18 standard-wide drawers and 2 double-wide drawers. 43”H x 90”W x 30”D'
  },
  {
    model: 'MM14',
    configuration: {
      Height: 700,
      Top: 'Capped',
      RiserHeight: 18,
      Wheels: true,
      Depth: 'S',
      Columns: ['SP', 'SP', 'SP'],
      Drawers: [
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },

        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },

        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' }
      ],
      FColor: '820000',
      DColor: '000004'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/460b6bfc-e948-43ed-14aa-37adb4ba7300/public',
    description:
      'Mobile tool storage with 15 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 100”W x 30”D'
  },
  {
    model: 'MM20',
    configuration: {
      Height: 700,
      Top: 'Capped',
      RiserHeight: 18,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S', 'S', 'S'],
      Drawers: [
        { d: true, h: 120, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },

        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },

        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' },

        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' }
      ],
      FColor: '002885',
      DColor: '002885'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/270cee06-4034-45bc-8e8b-77f7e2430200/public',
    description:
      'Mobile tool storage with 19 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 120”W x 30”D'
  },
  {
    model: 'MM33',
    configuration: {
      Height: 700,
      Top: 'Capped',
      RiserHeight: 24,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S', 'N', 'S', 'S'],
      Drawers: [
        { d: true, h: 80, l: 'Empty' },
        { d: true, h: 140, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },

        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },


        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 180, l: 'Empty' },
        { d: false, h: 180, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' },


        { d: true, h: 80, l: 'Empty' },
        { d: true, h: 140, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' },


        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' }
      ],
      FColor: '0B6422',
      DColor: 'EAC400',
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/e0d2a14e-d4bb-46f4-59dd-c4db73d08400/public',
    description:
      'Mobile tool storage with 18 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 144”W x 30”D'
  },
  {
    model: 'MM40',
    configuration: {
      Height: 700,
      Top: 'Capped',
      RiserHeight: 24,
      Wheels: true,
      Depth: 'S',
      Columns: ['S', 'S', 'EP', 'S', 'S'],
      Drawers: [
        { d: true, h: 140, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },

        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },

        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },

        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 260, l: 'Empty' },

        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 260, l: 'Empty' },
      ],
      FColor: '0B6422',
      DColor: 'EAC400',
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/11a4ed49-50a0-4c10-ba03-e6c2d79c0f00/public',
    description:
      'Mobile tool storage with 23 drawers, stainless steel work surface and riser. Designed for tool storage in a workstation application. 43”H x 168”W x 30”D'
  },
  {
    model: 'SW700-041',
    configuration: {
      Height: 700,
      Top: 'Stainless',
      RiserHeight: 0,
      Wheels: false,
      Depth: 'S',
      Columns: ['S'],
      Drawers: [
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 100, l: 'Empty' },
        { d: false, h: 200, l: 'Empty' },
        { d: false, h: 300, l: 'Empty' }
      ],
      FColor: '820000',
      DColor: '303039'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/aab89e6d-8bd2-43eb-659b-92470c049e00/public',
    description: 'Bench height tool storage with 4 drawers, 33 1/4”H x 29 1/4”W x 27 3/4”D.'
  },
  {
    model: 'SW900-071',
    configuration: {
      Height: 900,
      Top: 'Stainless',
      RiserHeight: 0,
      Wheels: false,
      Depth: 'S',
      Columns: ['S'],
      Drawers: [
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 80, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 240, l: 'Empty' }
      ],
      FColor: '820000',
      DColor: '303039'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/aa79275e-0c45-4aca-07cc-07034dc92500/public',
    description: 'Counter Height (seated) tool storage with 7 drawers, 41 1/2”H x 29 1/4”W x 27 3/4”D.'
  },
  {
    model: 'SW1360-091',
    configuration: {
      Height: 1360,
      Top: 'Stainless',
      RiserHeight: 0,
      Wheels: false,
      Depth: 'S',
      Columns: ['S'],
      Drawers: [
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 120, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 140, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: false, h: 160, l: 'Empty' },
        { d: true, h: 200, l: 'Empty' }
      ],
      FColor: '820000',
      DColor: '303039'
    },
    imageUrl: 'https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/e94af9ac-44d2-4806-6c51-e942f7e90100/public',
    description: 'Eye level height tool storage with 9 drawers, 59 1/2”H x 29 1/4”W x 27 3/4”D.'
  }
];

export {
  columnsWidthOptions,
  depthOptions,
  drawerHeightOptions,
  risersOptions,
  topOptions,
  BestSellingModels,
  drawerLayoutOptions,
  newSchemaBestSellingModels
};
