import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';
import { POST } from '../../utils/apis';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { allPaths } from '../../utils/constants';
import NorthWestIcon from '@mui/icons-material/NorthWest';

const SearchBox = () => {
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);

  const editor = (id) => {
    if (id === '63742f7d92623bbb479a2176') {
      return window.location.replace(`${allPaths.STORE}?storeId=345${id}&id=${id}`);
    }
    return window.location.replace(`${allPaths.EDITOR}?id=${id}`);
  };

  useEffect(() => {
    let active = true; // eslint-disable-line  no-unused-vars
    if (value === '' || inputValue === '') {
      setOptions([]);
    } else {
      axios.post(POST.GET_ALL_MODELS, { search: inputValue }).then((results) => {
        setOptions(results.data?.data || []);
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  return (
    <Autocomplete
      id="search-products"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      open={open}
      onOpen={() => {
        // only open when in focus and inputValue is not empty
        if (inputValue.length > 2) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      options={options}
      value={value}
      loading={true}
      loadingText={'...'}
      noOptionsText="Opps! No match found."
      onChange={(event, option) => {
        setValue(option.name);
        editor(option.id);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        const matches = match(option.name, inputValue, { insideWords: true });
        const parts = parse(option.name, matches);

        return (
          <Box
            component="li"
            {...props}
            sx={{
              justifyContent: 'space-between !important',
              minHeight: '20px'
            }}
          >
            <Box>
              {parts.map((part, index) => (
                <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                  {part.text}
                </Box>
              ))}
            </Box>
            <NorthWestIcon sx={{ color: '#A6A6A6' }} />
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          ref={params.InputProps.ref}
          {...params}
          inputProps={{
            ...params.inputProps
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ /* width: '14px', height: '14px', */ pr: '8px' }} />
              </InputAdornment>
            ),
            disableUnderline: true,
            sx: {
              '& .MuiInput-input': {
                fontSize: '14px',
                lineHeight: 1.4,
                boxSizing: 'border-box',
                padding: '15px 15px 15px 0!important'
              }
            }
          }}
          name="searchProduct"
          placeholder="Search"
          className="header-search"
          variant="standard"
          sx={{
            padding: '5px 15px !important',
            width: {
              xs: '100%',
              md: 'auto'
            },
            border: 'none',
            background: 'transperent',
            boxSizing: 'border-box',
            '& .MuiInput-root': {
              minHeight: '40px !important',
              borderRadius: {
                xs: 'none !important',
                md: '20px !important'
              }
            }
          }}
        />
      )}
    />
  );
};

export default SearchBox;
