import React from 'react';
import { hot } from 'react-hot-loader/root';
import axios from 'axios';
import AOS from 'aos';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Config/routes';

AOS.init();

AOS.refresh();

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    config.baseURL = process.env.REACT_APP_API_BASEURL;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const App = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default hot(App);
