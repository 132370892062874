import React from 'react';
import { Alert, Typography, useTheme } from '@mui/material';

const MinOrderBanner = (props) => {
  const { modelData } = props;
  const theme = useTheme();
  return (
    <Alert
      severity="info"
      style={{
        background: theme.palette.primary.dark,
        color: '#FFFFFF',
        justifyContent: 'center',
        fontSize: '16px',
        width: '100%',
        fontWeight: 700
      }}
    >
      {modelData?.minorderval && modelData.minorderval > 0 && (
        <Typography variant="span">Minimum Order Value per product is ${modelData.minorderval}.</Typography>
      )}
      {modelData?.setupCharge && modelData.setupCharge > 0 && (
        <Typography variant="span" ml={2}>
          Setup charge per design is ${modelData.setupCharge}.
        </Typography>
      )}
    </Alert>
  );
};

export default MinOrderBanner;
