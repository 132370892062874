import React from 'react';
import { allPaths, storLocModalId } from '../../utils/constants';
import { Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const SoldBy = ({ modelData }) => {
  const theme = useTheme();
  return (
    <Typography>
      Sold By{' '}
      <Link
        to={`${allPaths.PRODUCTS}/seller/${modelData?.seller && modelData?.seller != null ? `${modelData?.seller?._id}` : `spokbee`}`}
        style={{ color: theme.palette.primary.dark }}
      >
        {modelData?.seller && modelData?.seller != null ? `${modelData?.seller?.storeName}` : `Spokbee`}
      </Link>
      {storLocModalId === modelData?.modelId && modelData?.seller?.phoneNumber != '' && (
        <Typography>{modelData?.seller?.phoneNumber}</Typography>
      )}
    </Typography>
  );
};

export default SoldBy;
