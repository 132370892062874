// project import
import admin from './admin';

import seller from './seller';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (userRole, permissions) => {
  if (userRole == 2) {
    let sellerItems = { ...seller };

    sellerItems.children = seller.children.filter((item) => {
      const hasPermission = item.permissions.filter((permission) => permissions.includes(permission));

      return hasPermission.length > 0;
    });

    return { items: [sellerItems] };
  }
  if (userRole == 1) return { items: [admin] };
};

export default menuItems;
