// material-ui
import { Box, Typography, useMediaQuery, useTheme, Stack } from '@mui/material';

import Profile from './Profile';
import MobileSection from './MobileSection';
// import Notification from './Notification';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { allPaths } from '../../../../utils/constants';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = (props) => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  return (
    <>
      {/* {!matchesXs && <Search />} */}
      {/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}

      {/* <Box sx={{ width: '100%', ml: 1 }} /> */}

      {/* <Notification /> */}

      <Box sx={{ width: '100%', ml: 1, textAlign: 'center', color: theme.palette.primary.dark }}>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center', width: '100%', justifyContent: 'end' }}>
          {/* <Notification /> */}
        </Stack>
      </Box>

      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
