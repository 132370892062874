import React, { memo } from 'react';

import CategoriesList from '../../Components/Home/Categories';
import FeaturedProducts from '../../Components/Home/FeaturedProducts';
import WhyChoose from '../../Components/Home/WhyChoose';
import OurPartners from '../../Components/Home/OurPartners';

const Home = memo((props) => {
  const { categories } = props;

  return (
    <>
      <CategoriesList categories={categories} />
      <FeaturedProducts />
      {/* <BestSelling /> */}
      <WhyChoose />
      {/* <UpcomingLaunches /> */}
      <OurPartners />
    </>
  );
});

export default Home;
