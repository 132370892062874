import { LOGIN_CUSTOMER, REMOVE_CUSTOMER } from '../types';

const loginCustomer = (customer) => {
  return {
    type: LOGIN_CUSTOMER,
    customer
  };
};

const removeCustomer = () => {
  return {
    type: REMOVE_CUSTOMER
  };
};

export { loginCustomer, removeCustomer };
