import { Box, Button, Input, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { POST } from '../../utils/apis';
import { ErrorMessage } from '../Common';
import { useSnackbar } from 'notistack';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// eslint-disable-next-line  no-unused-vars
const NewsLetterSubscriber = (props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (formData) => {
    axios
      .post(POST.SUBSCRIBE_NEWSLETTER, formData)
      .then((res) => {
        if (res.data.success === true) {
          enqueueSnackbar(res.data.message, { variant: 'success' });
          reset({
            email: ''
          });
        } else {
          enqueueSnackbar(res.data.message, { variant: 'error' });
        }
      })
      .catch((e) => {
        const res = e.response;
        enqueueSnackbar(res.data.message, { variant: 'error' });
      });
  };

  return (
    <Box
      sx={{
        background: 'url(https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/cedc898d-d7d2-4749-9987-f0559c321000/public)',
        backgroundSize: 'cover'
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            background: theme.palette.primary.yellow,
            py: {
              xs: '40px',
              sm: '50px',
              md: '65px',
              lg: '80px'
            },
            textAlign: 'center'
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              lineHeight: 1.4,
              fontSize: {
                xs: '25px',
                sm: '35px',
                md: '40px',
                lg: '48px'
              },
              marginBottom: '15px'
            }}
          >
            <Typography variant="span" sx={{ color: theme.palette.custom.white }}>
              Newsletter{' '}
            </Typography>
            <Typography variant="span" sx={{ color: theme.palette.primary.main }}>
              Subscription
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              lineHeight: 1.4,
              color: theme.palette.custom.white,
              fontSize: {
                xs: '16px',
                sm: '20px',
                md: '24px',
                lg: '30ox'
              }
            }}
          >
            for new upcoming project launches
          </Typography>
          <Box
            sx={{
              margin: '0 auto',
              marginTop: '40px',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '30px',
              rowGap: '30px',
              flexDirection: {
                xs: 'column',
                sm: 'row'
              }
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto'
                }
              }}
            >
              <Input
                disableUnderline={true}
                placeholder="Email Address"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Invalid email format'
                  }
                })}
                sx={{
                  fontSize: '14px',
                  lineHeight: 1.4,
                  color: theme.palette.custom.white,
                  padding: '5px 10px',
                  borderRadius: '20px',
                  border: `1px solid ${theme.palette.custom.white}`,
                  minWidth: {
                    xs: '90%',
                    sm: '400px'
                  }
                }}
              />
              {errors.email && <ErrorMessage msg={errors?.email?.message} />}
            </Box>
            <Button
              variant="contained"
              type="submit"
              sx={{
                borderRadius: '20px',
                color: theme.palette.custom.white,
                textTransform: 'none',
                backgroundColor: theme.palette.primary.dark,
                fontSize: '16px',
                lineHeight: 1.5,
                fontWeight: 700,
                padding: '8px 16px'
              }}
            >
              Subscribe Now <KeyboardArrowRightIcon />
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default NewsLetterSubscriber;
