import * as React from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // eslint-disable-line  no-unused-vars
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { allPaths } from '../../utils/constants';
import { useState } from 'react';
import { useEffect, useContext } from 'react';
import { SnipcartContext } from '../Context/SnipcartProvider';

// eslint-disable-next-line  no-unused-vars
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    padding: '0 4px'
  }
}));
// eslint-disable-next-line  no-unused-vars
const HeaderAppBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [auth, setAuth] = useState(true); // eslint-disable-line  no-unused-vars
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [cartItemsCount, setCartItemsCount] = useState(0);

  const { snipcartReady } = useContext(SnipcartContext);

  useEffect(() => {
    if (snipcartReady) {
      const Snip = window.Snipcart;
      const initialState = Snip.store.getState();
      setCartItemsCount(initialState.cart.items.count);

      const unsubscribe = Snip.store.subscribe(() => {
        const newState = Snip.store.getState();
        setCartItemsCount(newState.cart.items.count);
      });

      return () => unsubscribe();
    }
  }, [cartItemsCount, snipcartReady]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link style={{ color: 'black' }} to={allPaths.ABOUT}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton style={{ outline: 'none' }}>
            <InfoIcon style={{ color: 'black' }} />
          </IconButton>
          <p style={{ marginBottom: 0 }}>About Us</p>
        </MenuItem>
      </Link>
      <Link style={{ color: 'black' }} to={allPaths.CONTACT}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton style={{ outline: 'none' }}>
            <PhoneIcon style={{ color: 'black' }} />
          </IconButton>
          <p style={{ marginBottom: 0 }}>Contact Us</p>
        </MenuItem>
      </Link>
    </Menu>
  );
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
            <img src="/assets/spokbee.png" height={54} width={200} alt="Spokbee" />
          </Link>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <Box className="about-us-head">
              <Link /* style={{ color: 'white' }} */ className="header_text" to={allPaths.ABOUT}>
                About Us
              </Link>
            </Box>
            <Box className="about-us-head">
              <Link className="header_text" to={allPaths.CONTACT}>
                Contact Us
              </Link>
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};

export default HeaderAppBar;
