import React, { useState, useEffect } from 'react';

function useIframe() {
  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    if (window.location !== window.parent.location) {
      setIsIframe(true);
    } else {
      setIsIframe(false);
    }
  }, []);

  return isIframe;
}

export default useIframe;
