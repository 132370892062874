const { ACTIVE_ITEM, ACTIVE_COMPONENT, OPEN_DRAWER } = require('../types');

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVE_ITEM: {
      return { ...state, ...action.openItem };
    }
    case ACTIVE_COMPONENT: {
      return { ...state, ...action.openComponent };
    }
    case OPEN_DRAWER: {
      return { ...state, ...action.drawerOpen };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
