import { Box, Toolbar } from '@mui/material'
import { matchPath, Outlet, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../Redux/actions/menuActions';

import Drawer from './Drawer';
import Header from './Header';
import { useEffect, useState } from 'react';
import { allPaths } from '../../utils/constants';
import axios from 'axios';


function CustomerLayout() {

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menuReducer);
  const { customer } = useSelector((state) => state.customerAuthReducer || { customer: null });
  const navigate = useNavigate();

  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.request.use(
      (request) => {
        if (customer?.accessToken) {
          request.headers.Authorization = `Bearer ${customer.accessToken}`;
        }
        return request;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [navigate]);

  useEffect(() => {
    if (!customer) {
      navigate(allPaths.CUSTOMER.LOGIN);
    }
  }, [customer]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>

      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />

      <Box
          component="main"
          sx={{
            width: open ? `calc(100vw - 240px)` : '100vw',
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
            overflowX: 'hidden',
          }}
        >
          <Toolbar />
          {/* <Breadcrumbs navigation={navigation(admin?.userRole, admin?.permissionGroup)} title /> */}
          <Outlet />
        </Box>
    </Box>
  )
}

export default CustomerLayout