// project import
import customer from './customer';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = (userRole) => {
  if (userRole == 3) return { items: [customer] };
};

export default menuItems;
