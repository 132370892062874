// material-ui
import { Box } from '@mui/material';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <Box component="img" src="/assets/spokbee.png" alt="Spokbee" />;
};

export default Logo;
