import { message, notification } from 'antd';
import allPaths from '../Config/paths';

const requiredMessage = (value) => `Please input your ${value}!`;

const inputPlace = (value) => `Input your ${value} Here...!`;

const setActiveMenu = (path) => (path === allPaths.HOME ? 0 : 1);

const successMessage = (desc = 'Successfully Complete!') => {
  return message.success(desc);
};

const infoMessage = (desc = 'Successfully Complete!') => {
  return message.info(desc);
};

const errorMessage = (desc = 'Oops Something Went Wrong!') => {
  // alert(desc)
  return desc;
};

const warningMessage = (desc = 'Warning!') => {
  return message.warning(desc);
};

const successNotification = (message = 'Successfully Complete!') => {
  return notification.success({ message });
};

const errorNotification = (message = 'Oops Something Went Wrong!') => {
  return notification.error({ message });
};

const convertTitle = (val) => val?.charAt(0)?.toUpperCase() + val?.slice(1);

const stringLimiter = (val, limit = 50) => (val?.length > limit ? `${val.slice(0, limit)}...` : val);

const roundPrice = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

const getConfiguratorUnit = (unitString) => {
  switch (unitString) {
    case 'meter':
    case 'meters':
      return 'm';

    case 'millimeter':
    case 'millimeters':
      return 'mm';

    case 'centimeter':
    case 'centimeters':
      return 'cm';

    case 'inch':
    case 'inches':
      return 'in';

    case 'foot':
    case 'feet':
      return 'ft';

    case 'yard':
    case 'yards':
      return 'yd';

    case 'degree':
    case 'degrees':
      return 'deg';

    case 'radian':
    case 'radians':
      return 'rad';

    default:
      return '';
  }
};

const generateconfigJSON = (json, arr = [], optChain = '') => {
  const childJson = json?.options ? Object?.keys(json?.options) : {};
  optChain = optChain != '' ? `${optChain}.${json.name}` : json.name;
  let obj = {
    label: json?.label,
    name: json?.name,
    disabled: false,
    optChain: optChain,
    valueType: json?.valueType || '13'
  };
  arr.push(obj);
  if (childJson?.length > 0) {
    if (json?.options[childJson[0]]?.name !== undefined) {
      generateconfigJSON(json?.options[childJson[0]], arr, optChain);
    }
  }

  return arr;
};

const getConfigParamsSchema = (configJSon) => {
  if (configJSon && configJSon != null && Object?.keys(generateconfigJSON).length > 0) {
    return [];
  }
  const params = generateconfigJSON(configJSon);
  return params;
};

export {
  requiredMessage,
  inputPlace,
  setActiveMenu,
  successMessage,
  infoMessage,
  errorMessage,
  warningMessage,
  successNotification,
  errorNotification,
  convertTitle,
  stringLimiter,
  roundPrice,
  getConfiguratorUnit,
  getConfigParamsSchema
};
