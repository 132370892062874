import { Button, Container, Divider, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 40,
    height: 40,
    cursor: 'pointer',
    borderRadius: '50%',
    fontSize: '14px',
    backgroundColor: theme.palette.secondary.light40,
    color: theme.palette.secondary.dark,
    border: 'none'
  };

  return (
    <div className="carousel-wrapper">
      <Carousel
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
              <KeyboardArrowLeftIcon />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>
              <KeyboardArrowRightIcon />
            </button>
          )
        }
      >
        <Box
          sx={{
            background: 'url(https://imagedelivery.net/4zyBIoy6n1eh8vSxjBoVOA/8922955e-cc5e-4667-c800-ab67842a6900/public)',
            backgroundSize: 'cover',
            py: {
              xs: '40px',
              sm: '80px',
              md: '250px',
              lg: '250px'
            },
            textAlign: 'left'
          }}
        >
          <Container>
            <Box
              id="banner-content-wrapper"
              sx={{
                maxWidth: {
                  xs: '100%',
                  md: '555px'
                }
              }}
            >
              <Typography 
               variant="span" 
               sx={{
                fontSize: {
                  xs: '26px',
                  md: '48px'
                },
                lineHeight: 1.4,
                fontWeight: 700,
                color: theme.palette.primary.dark
               }}
              >
                Spokbee
                <Typography 
                  component={"span"}
                   sx={{
                        fontSize:{
                          xs:'26px',
                          md:'48px'
                        },
                        marginLeft:2,
                        lineHeight:1.4,
                        fontWeight:700,
                        color: theme.palette.secondary.main, 
                      }}>
                    Marketplace
                </Typography>
              </Typography>
              <Typography
                sx={{
                  marginTop: '15px',
                  fontSize: '30px',
                  lineHeight: 1.4
                }}
              >
                Buy a Customizable Product!
              </Typography>


              <Divider
                sx={{
                  marginTop: '20px',
                  borderWidth: '3px',
                  borderColor: theme.palette.primary.dark,
                  width: '120px'
                }}
              />
              <Typography
                sx={{
                  marginTop: '20px',
                  fontSize: '16px',
                  lineHeight: 1.4,
                  color: theme.palette.secondary.dark,
                  fontWeight: 400
                }}
              >
                Spokbee has partnered with some of the best manufacturers in the world to manufacture and fufill customizable products on demand. Try it out for yourself.  
              </Typography>
              {/* <Button
                variant="contained"
                sx={{
                  marginTop: '30px',
                  borderRadius: '20px',
                  background: '#2DC78B',
                  color: theme.palette.custom.white,
                  padding: '12px 20px'
                }}
                onClick={() => window.open('https://spokbee.typeform.com/early-access')}
              >
                Request Beta Access Now
                <KeyboardArrowRightIcon />
              </Button> */}
            </Box>
          </Container>
        </Box>
      </Carousel>
    </div>
  );
};

export default Banner;
